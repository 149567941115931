import React, {ComponentType} from "react";

export  class ErrorBoundary extends React.Component<{
    children: React.ReactNode,

    fallback: ComponentType<{ error: any }>,
    onError?: (err:any, errorInfo:any) => void
}, { hasError: boolean, error: any}> {
    constructor(props: {
        children: React.ReactNode,
        fallback: ComponentType<{ error: any }>,
        onError?: ()=>void
    }) {
        super(props);
        this.state = { hasError: false, error: null } as { hasError: boolean, error: any};
    }

    static getDerivedStateFromError(error: any) {
        console.error("BoundaryError", error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error:any, errorInfo:any) {

        this.props.onError?.(error, errorInfo)
        // You can also log the error to an error reporting service
        // log error
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            const Fall = this.props.fallback
            return <Fall error={this.state.error}/>
        }
        return this.props.children;
    }
}