import {Action} from "@reduxjs/toolkit";

export type SpeedAction = Action<"Speed"> & {
    payload: number,
}

export const SpeedReducer = (state:number = 1, action: SpeedAction) => {
    if (action.type === "Speed") {
        if (action.payload > 2)
            return 2;
        if (action.payload < 0.5)
            return 0.5
        return action.payload;
    }
    return state;
};