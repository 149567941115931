
import {useNavigate} from "react-router-dom";
import {startTransition, forwardRef, ForwardedRef, CSSProperties} from "react";
import {useDomain} from "../contexts/useDomain";

type LinkProps = {
    to: string,
    target?: "_blank"|"_self",
    children: React.ReactNode,
    className?: string,
    onClick?: (ev: React.MouseEvent<HTMLAnchorElement>)=>void,
    onMouseEnter?: (ev: React.MouseEvent<HTMLAnchorElement>) => void,
    onMouseLeave?: (ev: React.MouseEvent<HTMLAnchorElement>) => void,
    onMouseOver?: (ev: React.MouseEvent<HTMLAnchorElement>) => void,
    title?: string,
    style?: CSSProperties
}

export const RouteLink = forwardRef((props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const navigation = useNavigate();
    const domain = useDomain();
    let newProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = { ...props};
    newProps.href = props.to
    // @ts-ignore
    delete newProps.to;

    return <a ref={ref} {...newProps} onClick={e=> {
        if (e.isPropagationStopped())
            return;

        props.onClick?.(e);

        if (e.isDefaultPrevented()) {
            return;
        }
        if (props.target === "_blank") {
            return;
        }

        let url = props.to;
        if (props.to.indexOf(domain) === 0) {
            url = props.to.replace(domain, "").replace(/^\/+/, "/");
        }

        if (url.match(/https?:/))
        {
            document.location = url;
            return;
        }

        e.preventDefault();
        startTransition(() => navigation(url));
    }}>
        {props.children}
    </a>
});