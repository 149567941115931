/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebMsConnectExtendServiceToUserModel
 */
export interface FrontWebMsConnectExtendServiceToUserModel {
    /**
     * Google
     * @type {string}
     * @memberof FrontWebMsConnectExtendServiceToUserModel
     */
    googleId?: string;
    /**
     * Facebook
     * @type {string}
     * @memberof FrontWebMsConnectExtendServiceToUserModel
     */
    facebookId?: string;
    /**
     * Apple
     * @type {string}
     * @memberof FrontWebMsConnectExtendServiceToUserModel
     */
    appleId?: string;
    /**
     * Mail
     * @type {string}
     * @memberof FrontWebMsConnectExtendServiceToUserModel
     */
    email?: string;
    /**
     * Client
     * @type {string}
     * @memberof FrontWebMsConnectExtendServiceToUserModel
     */
    clientId: string;
    /**
     * Secret
     * @type {string}
     * @memberof FrontWebMsConnectExtendServiceToUserModel
     */
    clientSecret: string;
}

/**
 * Check if a given object implements the FrontWebMsConnectExtendServiceToUserModel interface.
 */
export function instanceOfFrontWebMsConnectExtendServiceToUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "clientSecret" in value;

    return isInstance;
}

export function FrontWebMsConnectExtendServiceToUserModelFromJSON(json: any): FrontWebMsConnectExtendServiceToUserModel {
    return FrontWebMsConnectExtendServiceToUserModelFromJSONTyped(json, false);
}

export function FrontWebMsConnectExtendServiceToUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebMsConnectExtendServiceToUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'googleId': !exists(json, 'google_id') ? undefined : json['google_id'],
        'facebookId': !exists(json, 'facebook_id') ? undefined : json['facebook_id'],
        'appleId': !exists(json, 'apple_id') ? undefined : json['apple_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'clientId': json['client_id'],
        'clientSecret': json['client_secret'],
    };
}

export function FrontWebMsConnectExtendServiceToUserModelToJSON(value?: FrontWebMsConnectExtendServiceToUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'google_id': value.googleId,
        'facebook_id': value.facebookId,
        'apple_id': value.appleId,
        'email': value.email,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
    };
}

