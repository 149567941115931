import {lazy, startTransition, Suspense, useEffect, useState} from "react";
import {Loadbar} from "../../../contexts/useLoader";

const BottomViewPlayerInner = lazy(() => import('./BottomInner'));

export const BottomViewPlayer = () => {
    const [inClient, setInClient] = useState(false);
    useEffect(() => {
        startTransition(() => setInClient(true));
    }, []);
    if (inClient)
        return <Suspense fallback={<Loadbar loading={true} /> }><BottomViewPlayerInner/></Suspense>
    return null;
}