import {useState} from "react";
import Css from "./TuneOn.module.scss";
import routes from "../../utils/routes";
import { RouteLink} from "../RouteLink";
import { slugifyText} from "../../utils/slugifyText";

import browserimg from "../../static/tuneon/browser.svg";
import appimg from "../../static/tuneon/app.svg";
import wifiradioimg from '../../static/tuneon/wifiradio.svg';
import airplayimg from "../../static/tuneon/airplay.svg";
import carimg from '../../static/tuneon/car.svg';
export const TuneOn = (props: {
    className?: string,
    asTop?: boolean
}) => {
    const [data] = useState(() => ([
        { src: browserimg, title: "Web", uri: routes.text_jak_si_nas_naladite(slugifyText("Web"))},
        { src: appimg, title: "Aplikace", uri: routes.text_jak_si_nas_naladite(slugifyText("Aplikace"))},
        { src: wifiradioimg, title: "Wifi Rádio", uri: routes.text_jak_si_nas_naladite(slugifyText("Wifi Rádio"))},
        { src: airplayimg, title: "Airplay & Chromecast", uri: routes.text_jak_si_nas_naladite(slugifyText("Airplay & Chromecast"))},
        { src: carimg , title: "Car play", uri: routes.text_jak_si_nas_naladite(slugifyText("Car play"))},
    ]));


    return <div className={props.className ?? ""}>
        {props.asTop ?
            <h1 className={" text-center"}>Jak nás naladíte</h1> :
            <h2 className={" text-center"}>Jak nás naladíte</h2>
        }
        <div className={`opacity-70 text-center ${Css.marginBottom}`}>Poslouchejte rádia. Kdykoliv. Kdekoliv</div>
        <div className={`d-flex justify-content-center align-items-center flex-wrap flex-md-nowrap ${props.asTop ? Css.asTop : ""}`}>
            {data.map((item, key)=> {
                return <RouteLink key={key} className={`${Css.Width}`} to={item.uri} >
                    <span>
                        <img src={item.src} alt={item.title}/>
                    </span>
                    <span>{item.title}</span>
                </RouteLink>
            })}
        </div>
    </div>
}