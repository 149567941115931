/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebStatisticsPositionModel
 */
export interface FrontWebStatisticsPositionModel {
    /**
     * Position
     * @type {string}
     * @memberof FrontWebStatisticsPositionModel
     */
    position: string;
    /**
     * 
     * @type {number}
     * @memberof FrontWebStatisticsPositionModel
     */
    width: number;
    /**
     * 
     * @type {boolean}
     * @memberof FrontWebStatisticsPositionModel
     */
    block: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontWebStatisticsPositionModel
     */
    used: boolean;
}

/**
 * Check if a given object implements the FrontWebStatisticsPositionModel interface.
 */
export function instanceOfFrontWebStatisticsPositionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "block" in value;
    isInstance = isInstance && "used" in value;

    return isInstance;
}

export function FrontWebStatisticsPositionModelFromJSON(json: any): FrontWebStatisticsPositionModel {
    return FrontWebStatisticsPositionModelFromJSONTyped(json, false);
}

export function FrontWebStatisticsPositionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebStatisticsPositionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': json['position'],
        'width': json['width'],
        'block': json['block'],
        'used': json['used'],
    };
}

export function FrontWebStatisticsPositionModelToJSON(value?: FrontWebStatisticsPositionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'width': value.width,
        'block': value.block,
        'used': value.used,
    };
}

