
import { PlayedMount } from "./types";

export const MountReducer = (state:boolean = false, action: PlayedMount) => {

    if (action.type === "PlayerMount") {
        if (action.payload === "Mount")
            return true;
        return false;
    }
    return state;
}