
import type { SearchProps } from "./Search";
import {lazy, Suspense, useEffect, useState} from "react";
import {Loadbar} from "../../contexts/useLoader";
const Search = lazy(() => import('./Search'))

export const LazySearch = (props: SearchProps) => {
    const [inClient, setInClient] = useState(false);
    useEffect(() => {
        setInClient(true);
    }, []);
    if (inClient)
        return <Suspense fallback={<Loadbar loading={true} />}><Search {...props} /></Suspense>
    return null;
}