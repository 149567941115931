/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebRedirectRuleModel
 */
export interface FrontWebRedirectRuleModel {
    /**
     * Old regex path
     * @type {string}
     * @memberof FrontWebRedirectRuleModel
     */
    oldPath: string;
    /**
     * New regex path
     * @type {string}
     * @memberof FrontWebRedirectRuleModel
     */
    newPath: string;
}

/**
 * Check if a given object implements the FrontWebRedirectRuleModel interface.
 */
export function instanceOfFrontWebRedirectRuleModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "oldPath" in value;
    isInstance = isInstance && "newPath" in value;

    return isInstance;
}

export function FrontWebRedirectRuleModelFromJSON(json: any): FrontWebRedirectRuleModel {
    return FrontWebRedirectRuleModelFromJSONTyped(json, false);
}

export function FrontWebRedirectRuleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebRedirectRuleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldPath': json['oldPath'],
        'newPath': json['newPath'],
    };
}

export function FrontWebRedirectRuleModelToJSON(value?: FrontWebRedirectRuleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oldPath': value.oldPath,
        'newPath': value.newPath,
    };
}

