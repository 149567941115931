/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebRadioModel } from './FrontWebRadioModel';
import {
    FrontWebRadioModelFromJSON,
    FrontWebRadioModelFromJSONTyped,
    FrontWebRadioModelToJSON,
} from './FrontWebRadioModel';

/**
 * 
 * @export
 * @interface FrontWebSearchResultRadiosModel
 */
export interface FrontWebSearchResultRadiosModel {
    /**
     * Počet stránek
     * @type {number}
     * @memberof FrontWebSearchResultRadiosModel
     */
    pages: number;
    /**
     * Celkový počet
     * @type {number}
     * @memberof FrontWebSearchResultRadiosModel
     */
    count: number;
    /**
     * Rádia
     * @type {Array<FrontWebRadioModel>}
     * @memberof FrontWebSearchResultRadiosModel
     */
    result: Array<FrontWebRadioModel>;
    /**
     * Fráze
     * @type {boolean}
     * @memberof FrontWebSearchResultRadiosModel
     */
    byPhrase: boolean;
}

/**
 * Check if a given object implements the FrontWebSearchResultRadiosModel interface.
 */
export function instanceOfFrontWebSearchResultRadiosModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "byPhrase" in value;

    return isInstance;
}

export function FrontWebSearchResultRadiosModelFromJSON(json: any): FrontWebSearchResultRadiosModel {
    return FrontWebSearchResultRadiosModelFromJSONTyped(json, false);
}

export function FrontWebSearchResultRadiosModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebSearchResultRadiosModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pages': json['pages'],
        'count': json['count'],
        'result': ((json['result'] as Array<any>).map(FrontWebRadioModelFromJSON)),
        'byPhrase': json['byPhrase'],
    };
}

export function FrontWebSearchResultRadiosModelToJSON(value?: FrontWebSearchResultRadiosModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pages': value.pages,
        'count': value.count,
        'result': ((value.result as Array<any>).map(FrontWebRadioModelToJSON)),
        'byPhrase': value.byPhrase,
    };
}

