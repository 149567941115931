import type { Action } from "@reduxjs/toolkit";
export type MaxSize = {
    width: number,
    height: number,
    padding: number
}

export type MaxSizeAction = Action<"MaxSize"> & {
    payload: MaxSize
}

export const MaxSizeReducer = (state = {
    width: 660,
    height: 800,
    padding: 10
}, action: MaxSizeAction) => {
    if (action.type === "MaxSize") {
        return action.payload;
    }
    return state;
}