import styles from "./Header.module.scss";
import {useSelector} from "react-redux";
import { RootState} from "../Store";

import {lazy, Suspense, useCallback, useRef, useState} from "react";
import { ClickOuterDiv} from "../Hook/useClickOuter";
import { RouteLink } from "../RouteLink";
import { HeaderLink} from "./HeaderLink";

import userloggedimg from '../../static/userlogged.svg';
import rectangleimg from '../../static/rectangle.svg';
import userimg from '../../static/user.svg';

const Overlay = lazy(() => import( "react-bootstrap/Overlay"));

export const SmallUserMenu = () =>
{
    const hasCurrentUser = useSelector((x: RootState) => x.currentUser);
    const smallUrls = [
        {
            text: hasCurrentUser ? `Nastavení účtu` : "Přihlásit se",
            href: "/muj-ucet"
        }
    ];
    if (hasCurrentUser) {
        smallUrls.push({
            text: "Odhlásit se",
            href: "/odhlasit-se"
        })
    }

    return <>
        {smallUrls.map(x=>(
            <li key={x.href} className={"nav-item"}>
                <HeaderLink href={x.href} className={`nav-link ${styles.link}`}>
                    {x.text}
                </HeaderLink>
            </li>))}
    </>;
}


export const UserMenu = () => {
    const hasCurrentUser = useSelector((x: RootState) => x.currentUser);
    const [selected, setSelected] = useState(0);
    const buttonRef = useRef<HTMLAnchorElement|null>(null);
    const onHide = useCallback(() =>{
        setSelected(c => {
            c--;
            if (c < 0)
                return 0;
            return c;
        });
    },[]);

    return <>
        <RouteLink to="/muj-ucet" onMouseOver={_=>{
            setSelected(1);
        }} onClick={e=>{
            setSelected(0);
        }} ref={buttonRef} className={`${styles.link} d-flex justify-content-between align-content-center flex-wrap`}>
            <div><img alt={"účet"} src={hasCurrentUser ?  userloggedimg : userimg}/></div>
            <div>&nbsp;&nbsp;{hasCurrentUser ? "Můj účet" : "Přihlásit se"}</div>
        </RouteLink>
        {hasCurrentUser ?
        <Suspense><Overlay target={buttonRef.current} show={!!selected} placement={"bottom"}>
            {({
                  placement: _placement,
                  arrowProps: _arrowProps,
                  show: _show,
                  popper: _popper,
                  hasDoneInitialMeasure: _hasDoneInitialMeasure,
                  ...props
              }) => (
                <div
                    key={"own"}
                    {...props}
                    style={{
                        position: 'absolute',
                        zIndex:150,
                        padding: '10px 10px',
                        borderRadius: 10,
                        ...props.style,
                        transform: (props.style.transform ?? "") + " translate(-13px, 20px)"
                    }}

                >
                    <ClickOuterDiv onOutClick={onHide}>
                        <img src={rectangleimg} alt={""} className={styles.Rectangle} />
                        <ul className={styles.userMenu}>
                            <li><HeaderLink onClick={_=>{
                                setSelected(0);
                            }} href={'/muj-ucet'}>Nastavení</HeaderLink></li>
                            <li><HeaderLink onClick={_=>{
                                // musi byt a, jinak je tendence udelat preload a fuck you cookies
                                setSelected(0);
                            }} href={"/odhlasit-se"}>Odhlásit se</HeaderLink></li>
                        </ul>
                    </ClickOuterDiv>
                </div>
            )}
        </Overlay></Suspense> : null}
    </>
}