import baseloader from '../static/baseloader.svg';
export const Loader = (props: {height?: number})=> {
    return <div style={{height: `${props.height ?? 1000}px`}}
                className={"d-flex align-items-center text-center justify-content-center"}>

        <img className="mt-5 mb-5" width={300} style={{maxHeight: '300px'}}
             src={baseloader}
             alt={"loader"}/>
    </div>
}
