/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebSongModel } from './FrontWebSongModel';
import {
    FrontWebSongModelFromJSON,
    FrontWebSongModelFromJSONTyped,
    FrontWebSongModelToJSON,
} from './FrontWebSongModel';

/**
 * 
 * @export
 * @interface FrontWebSongsModel
 */
export interface FrontWebSongsModel {
    /**
     * Vytvořeno
     * @type {Array<string>}
     * @memberof FrontWebSongsModel
     */
    availableHours: Array<string>;
    /**
     * Kolik hodin zpět
     * @type {string}
     * @memberof FrontWebSongsModel
     */
    backHour: string;
    /**
     * Songy
     * @type {Array<FrontWebSongModel>}
     * @memberof FrontWebSongsModel
     */
    songs: Array<FrontWebSongModel>;
    /**
     * 
     * @type {string}
     * @memberof FrontWebSongsModel
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof FrontWebSongsModel
     */
    to: string;
}

/**
 * Check if a given object implements the FrontWebSongsModel interface.
 */
export function instanceOfFrontWebSongsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availableHours" in value;
    isInstance = isInstance && "backHour" in value;
    isInstance = isInstance && "songs" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;

    return isInstance;
}

export function FrontWebSongsModelFromJSON(json: any): FrontWebSongsModel {
    return FrontWebSongsModelFromJSONTyped(json, false);
}

export function FrontWebSongsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebSongsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableHours': json['availableHours'],
        'backHour': json['backHour'],
        'songs': ((json['songs'] as Array<any>).map(FrontWebSongModelFromJSON)),
        'from': json['from'],
        'to': json['to'],
    };
}

export function FrontWebSongsModelToJSON(value?: FrontWebSongsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availableHours': value.availableHours,
        'backHour': value.backHour,
        'songs': ((value.songs as Array<any>).map(FrontWebSongModelToJSON)),
        'from': value.from,
        'to': value.to,
    };
}

