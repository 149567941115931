import React, {createContext, startTransition, useContext, useEffect, useMemo, useState} from "react";
import LoadbarStyle from "./LoadbarStyle.module.scss";
import {createPortal} from "react-dom";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useQuery} from "@tanstack/react-query";
import {useUserKey} from "../queriesKeys/useUsers";
import {hasCurrentUser} from "../components/Store/currentUser";


const LoadbarContext = createContext({
    addLoad: () => {

    },
    removeLoad: () => {

    },
    reset: () => {

    },
})

export const useResetLoadbar = () =>  useContext(LoadbarContext).reset;

export const useLoader = () => useContext(LoadbarContext);

const Bar = () => {

    const [doc, setDoc] = useState<HTMLDivElement|null>(null);
    useEffect(() => {
        const doc = document.createElement("div");
        doc.className = `${LoadbarStyle.loadBar} addLoadbarMargin`;
        if (document.body.prepend) {
            document.body.prepend(doc);
            setDoc(doc);
            return () => {
                doc.style.display = "none !important";
                setTimeout(() => document.body.removeChild(doc), 2000);
            }
        }
    }, []);
    if (!doc)
        return null;

    return createPortal(<>
        <div className={`${LoadbarStyle.bar}`}></div>
        <div className={`${LoadbarStyle.bar}`}></div>
        <div className={`${LoadbarStyle.bar}`}></div>
    </>, doc);

}

export const LoadbarProvider = ({children}: { children: React.ReactNode }) => {

    const loc = useLocation();

    const isPopstate = useMemo(() => {
        let popState = false;
        if (typeof window !== "undefined") {
            window.addEventListener("popstate", (event) => {
                popState = true;
            }, true);

            return () => {
                if (popState) {
                    popState = false;
                    return true;
                }
                return false;
            }
        } else {
            return () => false;
        }
    }, []);


    const [findingElement, setFindingElement] = useState<[string, string, string]>([loc.pathname, loc.search, loc.search]);

    const dispatch = useDispatch();
    const {  refetch } = useQuery({ ...useUserKey(), enabled: false})

    useEffect(() => {
        startTransition(() => {
            (async () => {
                const {data} = await refetch();
                if (data) {
                    dispatch(hasCurrentUser(data.mail ?? "Nedef. mail"));
                }
            })();
        });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (isPopstate())
            return;
        const hash = loc.hash.replace("#", "");
        if (hash) {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView();
            } else {
                startTransition(() => setFindingElement([loc.pathname, loc.search, loc.hash]));
            }
            return;
        }
        try {
            window.scrollTo({
                top: 0
            });
        }
        catch (e) {}

    }, [loc.pathname, loc.search, loc.hash, loc.key]);

    const [loadCounter, setLoadCounter] = useState(0);

    useEffect(() => {
        let counter = 3;
        const hash = findingElement[2].replace("#", "");
        if (!hash)
            return;
        const tim = setInterval(() => {
            counter--;
            const element = document.getElementById(hash);
            if (element || counter <= 0) {
                if (!element) {
                    try {
                        window.scrollTo({
                            top: 0
                        })
                    }
                    catch (e) {

                    }
                } else {
                    try {
                        element.scrollIntoView();
                    }
                    catch (e) {

                    }
                }
                clearInterval(tim);
            }
        }, 200);
        return () => clearInterval(tim);
        // eslint-disable-next-line
    }, [findingElement]);

    const [loader] = useState(() => {

        return {
            addLoad:()=> {
                startTransition(()=> {
                    setLoadCounter(c => c + 1);
                });
            },
            removeLoad:() => {
                startTransition(()=> {
                    setLoadCounter(c => {
                        const newC = c - 1;
                        if (newC < 0) {
                            return 0;
                        }
                        return newC;
                    });
                });
            },
            reset: () => {
                startTransition(()=> {
                    setLoadCounter(0);
                });
            }
        }
    })


    return <LoadbarContext.Provider value={loader}>
        {loadCounter ? <Bar/> : null}
        {children}
    </LoadbarContext.Provider>
}


export const Loadbar = (props:{ loading: boolean, children?: React.ReactNode }) => {
    const ctx = useContext(LoadbarContext);
    useEffect(() => {
      if (props.loading) {
            ctx.addLoad();
            return () => {
                ctx.removeLoad();
            }
        }
    }, [props.loading, ctx]);

    return props.children ? <div>{props.children}</div> : <span style={{display: "none"}}/>;
}