/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontApplicationInputRegisterModel
 */
export interface FrontApplicationInputRegisterModel {
    /**
     * email
     * @type {string}
     * @memberof FrontApplicationInputRegisterModel
     */
    email: string;
    /**
     * password
     * @type {string}
     * @memberof FrontApplicationInputRegisterModel
     */
    password: string;
    /**
     * Client
     * @type {boolean}
     * @memberof FrontApplicationInputRegisterModel
     */
    gdprAggree: boolean;
    /**
     * Client
     * @type {string}
     * @memberof FrontApplicationInputRegisterModel
     */
    clientId: string;
    /**
     * Secret
     * @type {string}
     * @memberof FrontApplicationInputRegisterModel
     */
    clientSecret?: string;
}

/**
 * Check if a given object implements the FrontApplicationInputRegisterModel interface.
 */
export function instanceOfFrontApplicationInputRegisterModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "gdprAggree" in value;
    isInstance = isInstance && "clientId" in value;

    return isInstance;
}

export function FrontApplicationInputRegisterModelFromJSON(json: any): FrontApplicationInputRegisterModel {
    return FrontApplicationInputRegisterModelFromJSONTyped(json, false);
}

export function FrontApplicationInputRegisterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationInputRegisterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'gdprAggree': json['gdprAggree'],
        'clientId': json['client_id'],
        'clientSecret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
    };
}

export function FrontApplicationInputRegisterModelToJSON(value?: FrontApplicationInputRegisterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'gdprAggree': value.gdprAggree,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
    };
}

