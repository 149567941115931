import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";

const Ctx = createContext({
    "advertisementDebug": false
});

export const DebugProvider =  ({ children } : { children: React.ReactNode}) => {

    const {  search } = useLocation();

    const parseAdvertisementDebug = () => {
        if (typeof window !== "undefined") {
            const urlSearch = new URLSearchParams(search);
            const advertisementDebugUrl = urlSearch.get("advertisementDebug");

            if (advertisementDebugUrl === "1")
                return true;
            else if (advertisementDebugUrl === "0")
                return false;
            if (sessionStorage.getItem("advertisementDebug"))
                return true;
            return false;
        }
        return false;
    }

    const [advertisementDebug, setAdvertisementDebug] = useState(parseAdvertisementDebug);

    useEffect(() => {
        const active = parseAdvertisementDebug();
        if (active)
            sessionStorage.setItem("advertisementDebug", "1");
        else
            sessionStorage.removeItem("advertisementDebug");
        setAdvertisementDebug(active);
    }, [search]);

    const value = useMemo(()=> ({
        advertisementDebug
    }), [advertisementDebug]);

    return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
}

export const useDebug = () => {
    return useContext(Ctx);
}