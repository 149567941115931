
import {FrontWebRadioModel} from "../../../generated";
import routes from "../../../utils/routes";
import RadioCss from "../Style.module.scss";
import { LogoBox} from "../Image/LogoBox";
import {RouteLink} from "../../RouteLink";

export const RadioBox = (props: {
    className?: string,
    radio: FrontWebRadioModel
}) => {
    return <RouteLink to={routes.radio_homepage(props.radio.slug)} className={`${props.className || ""} ${RadioCss.LinkBox} d-flex flex-column align-items-center text-center`}>
        <LogoBox src={props.radio.logo} alt={props.radio.name}  shadow={true}/>
        <strong>{props.radio.name}</strong>
    </RouteLink>
}