/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebRadioModel } from './FrontWebRadioModel';
import {
    FrontWebRadioModelFromJSON,
    FrontWebRadioModelFromJSONTyped,
    FrontWebRadioModelToJSON,
} from './FrontWebRadioModel';

/**
 * 
 * @export
 * @interface FrontWebSearchResultCategoryRadiosModel
 */
export interface FrontWebSearchResultCategoryRadiosModel {
    /**
     * Název kategorie
     * @type {string}
     * @memberof FrontWebSearchResultCategoryRadiosModel
     */
    categoryName: string;
    /**
     * Počet stránek
     * @type {number}
     * @memberof FrontWebSearchResultCategoryRadiosModel
     */
    pages: number;
    /**
     * Celkový počet
     * @type {number}
     * @memberof FrontWebSearchResultCategoryRadiosModel
     */
    count: number;
    /**
     * Rádia
     * @type {Array<FrontWebRadioModel>}
     * @memberof FrontWebSearchResultCategoryRadiosModel
     */
    result: Array<FrontWebRadioModel>;
    /**
     * Fráze
     * @type {boolean}
     * @memberof FrontWebSearchResultCategoryRadiosModel
     */
    byPhrase?: boolean;
}

/**
 * Check if a given object implements the FrontWebSearchResultCategoryRadiosModel interface.
 */
export function instanceOfFrontWebSearchResultCategoryRadiosModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categoryName" in value;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function FrontWebSearchResultCategoryRadiosModelFromJSON(json: any): FrontWebSearchResultCategoryRadiosModel {
    return FrontWebSearchResultCategoryRadiosModelFromJSONTyped(json, false);
}

export function FrontWebSearchResultCategoryRadiosModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebSearchResultCategoryRadiosModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryName': json['categoryName'],
        'pages': json['pages'],
        'count': json['count'],
        'result': ((json['result'] as Array<any>).map(FrontWebRadioModelFromJSON)),
        'byPhrase': !exists(json, 'byPhrase') ? undefined : json['byPhrase'],
    };
}

export function FrontWebSearchResultCategoryRadiosModelToJSON(value?: FrontWebSearchResultCategoryRadiosModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryName': value.categoryName,
        'pages': value.pages,
        'count': value.count,
        'result': ((value.result as Array<any>).map(FrontWebRadioModelToJSON)),
        'byPhrase': value.byPhrase,
    };
}

