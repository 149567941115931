/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebErrorItemModel } from './FrontWebErrorItemModel';
import {
    FrontWebErrorItemModelFromJSON,
    FrontWebErrorItemModelFromJSONTyped,
    FrontWebErrorItemModelToJSON,
} from './FrontWebErrorItemModel';

/**
 * 
 * @export
 * @interface FrontWebErrorModel
 */
export interface FrontWebErrorModel {
    /**
     * Type error
     * @type {string}
     * @memberof FrontWebErrorModel
     */
    error: string;
    /**
     * Type error
     * @type {Array<FrontWebErrorItemModel>}
     * @memberof FrontWebErrorModel
     */
    data: Array<FrontWebErrorItemModel>;
}

/**
 * Check if a given object implements the FrontWebErrorModel interface.
 */
export function instanceOfFrontWebErrorModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function FrontWebErrorModelFromJSON(json: any): FrontWebErrorModel {
    return FrontWebErrorModelFromJSONTyped(json, false);
}

export function FrontWebErrorModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebErrorModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': json['error'],
        'data': ((json['data'] as Array<any>).map(FrontWebErrorItemModelFromJSON)),
    };
}

export function FrontWebErrorModelToJSON(value?: FrontWebErrorModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'data': ((value.data as Array<any>).map(FrontWebErrorItemModelToJSON)),
    };
}

