import {slugifyText} from "./slugifyText";

export const slugifyPath = (data: SlugifyPlayedItemType, simple: boolean = false) => {
    if ("radioSlug" in data) {
        if ("programSlug" in data) {
            if (!simple) {
                return `${data.radioSlug}/${data.programSlug}/${data.id}-${slugifyText(data.name)}`;
            }
            return `${data.programSlug}/${data.id}-${slugifyText(data.name)}`;
        }
        return `${data.radioSlug}`;
    }
    return `${data.programSlug}/${data.id}-${slugifyText(data.name)}`;
}
