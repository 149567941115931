import type { Action } from "@reduxjs/toolkit";
export type AdvertisementVastInput = {
    id: number,
    type: string,
    url: string,
    hour?: number,
    onMobile?: boolean
}

export type Advertisement = AdvertisementVastInput | false | undefined | null;

export type Advertisements = Advertisement[]

export type Input = {
    slug: string,
    streams?: string|string[],
    advertisements: Advertisements,
    triton?: string
}

export type InputState = Input | null

export type InputAction = Action<"Input"> & {
    payload: InputState
}

export type ReplaceInputAction = Action<"ReplaceInput"> & {
    payload: InputState
}

export const InputReducer = (state:InputState|undefined = null, action: InputAction|ReplaceInputAction) => (action.type === "Input" || action.type === "ReplaceInput" ? action.payload : (state ?? null))
