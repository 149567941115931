
import type { Action } from "@reduxjs/toolkit";

export type PlayerStatus = "PLAYING" | "LOADING" | "STOPPED";

export type PlayerRequiredStatus = "STOP" | "PLAY";

export type StatusRequiredAction = Action<"RequiredStatus"> & {
    payload: PlayerRequiredStatus
}
export type StatusAction = Action<"Status"> & {
    payload: PlayerStatus
}

export const StatusReducer = (state:PlayerStatus = "STOPPED", action: StatusAction) => {
    if (action.type === "Status") {
        return action.payload;
    }
    return state;
};