import {createContext, useCallback, useContext} from "react";
import {FrontWebPodcastEpisodeModel, FrontWebRadioDetailModel, FrontWebRadioModel} from "../generated";
import {slugifyPath} from "../utils/slugifyPath"


const RadioSlugContext = createContext<FrontWebRadioDetailModel|undefined>(undefined)

export const RadioProvider = (props: {
    children: React.ReactNode,
    radio: FrontWebRadioDetailModel
}) => <RadioSlugContext.Provider value={props.radio}>
    {props.children}
</RadioSlugContext.Provider>

export const useRadio = () =>{
    return useContext(RadioSlugContext);
}

export const usePlayItemCreator = () => {
    const ctx = useContext(RadioSlugContext);

    return useCallback((data: FrontWebRadioModel|FrontWebRadioDetailModel|FrontWebPodcastEpisodeModel|PlayedItemType): PlayedItemType => {
        if ("playedItemType" in data)
            return data;

        if ("pubDate" in data ) {
            const c = data as FrontWebPodcastEpisodeModel;
            if (ctx) {
                return {
                    radioName: ctx.name,
                    radioSlug: ctx.slug,
                    programName: c.programName,
                    programSlug: c.programSlug,
                    id: c.id,
                    name: c.name,
                    image: c.image,
                    duration: c.duration,
                    playedItemType: true
                }
            }
            return {
                programName: c.programName,
                programSlug: c.programSlug,
                id: c.id,
                image: c.image,
                name: c.name,
                duration: c.duration,
                playedItemType: true
            }
        }

        return { radioSlug: data.slug, radioName: data.name, image: data.logo, radioSlogan: data.slogan, playedItemType: true };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx?.slug]);
}

export const useSlugifyPath = () => {
    const ctx = useContext(RadioSlugContext);
    return useCallback ((data: FrontWebRadioModel|FrontWebRadioDetailModel|FrontWebPodcastEpisodeModel, simple: boolean = false) => {
        if ("pubDate" in data ) {
            const c = data as FrontWebPodcastEpisodeModel;
            if (ctx) {
                return slugifyPath({
                    radioSlug: ctx.slug,
                    programSlug: c.programSlug,
                    name: c.name,
                    id: c.id
                }, simple)
            }
            return slugifyPath({
                programSlug: c.programSlug,
                name: c.name,
                id: c.id
            }, simple)
        }

        return slugifyPath( { radioSlug: data.slug }, simple);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx?.slug]);
}