
import {lazy, startTransition, Suspense, useEffect, useState} from "react";
import {Loadbar} from "../../../contexts/useLoader";

const Inner = lazy(() => import('./TopInner'));

export const TopViewPlayer = () => {
    const [inClient, setInClient] = useState(false);
    useEffect(() => {
        startTransition(() =>setInClient(true));
    }, []);
    if (inClient) {
        return <Suspense fallback={<Loadbar loading={true} /> }><Inner/></Suspense>
    }
    return null;
}