import React, {createContext, lazy, Suspense, useCallback, useContext, useState} from "react";
import {FrontWebSongLyricsModel} from "../generated";
import {useAbortController} from "../components/Hook/useAbortController";
import {ApiClient} from "../generated/ApiClient";
import {Loadbar} from "./useLoader";

const Lyrics = lazy(() => import('./../components/Item/Song/Lyrics'));


const LyricsContext = createContext((radioSlug: string, songId: number) => {});

export const useLyrics = ()=>useContext(LyricsContext);

export const LyricsProvider = ( props: { children: React.ReactNode } ) => {
    const [lyrics, setLyrics] = useState<FrontWebSongLyricsModel|null>(null);
    const controller = useAbortController();

    const callback = useCallback((radioSlug: string,  songId: number) => {
        setLyrics(null);
        (async() => {
            const signal = controller()?.signal

            new ApiClient().getApiRadioSongLyrics({
                slug: radioSlug,
                id: songId
            }, {
                signal: signal
            }).then(x => {
                if (!!x.lyrics?.trim())
                    setLyrics(x);
            });
        })();
    }, [controller]);

    return <LyricsContext.Provider value={callback}>
        {props.children}
        {lyrics ?
            <Suspense fallback={<Loadbar loading={true}/>}>
                <Lyrics song={lyrics} />
            </Suspense> : null}
    </LyricsContext.Provider>
}