import type { Action } from "@reduxjs/toolkit";


export type VolumeAction = Action<"Volume"> & {
    payload: number
}

export const VolumeReducer = (state:number = 1, action: VolumeAction) => {
    if (action.type !== "Volume")
        return state;

    let newState = action.payload;
    if (isNaN(newState))
        newState = 1;
    if (newState > 1)
    {
        newState = 1;
    }
    if (newState < 0)
    {
        newState = 0;
    }
    return newState;
}