/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebRadioStreamPlaylistModel } from './FrontWebRadioStreamPlaylistModel';
import {
    FrontWebRadioStreamPlaylistModelFromJSON,
    FrontWebRadioStreamPlaylistModelFromJSONTyped,
    FrontWebRadioStreamPlaylistModelToJSON,
} from './FrontWebRadioStreamPlaylistModel';

/**
 * 
 * @export
 * @interface FrontWebRadioDetailModel
 */
export interface FrontWebRadioDetailModel {
    /**
     * Má info
     * @type {boolean}
     * @memberof FrontWebRadioDetailModel
     */
    hasDescription?: boolean;
    /**
     * Má podcast
     * @type {boolean}
     * @memberof FrontWebRadioDetailModel
     */
    hasPodcasts?: boolean;
    /**
     * Má skladby
     * @type {boolean}
     * @memberof FrontWebRadioDetailModel
     */
    hasPlaylist?: boolean;
    /**
     * Má lyrics
     * @type {boolean}
     * @memberof FrontWebRadioDetailModel
     */
    hasLyrics?: boolean;
    /**
     * Má další stanice
     * @type {boolean}
     * @memberof FrontWebRadioDetailModel
     */
    hasSimilars?: boolean;
    /**
     * Má seznam budoucích skladeb
     * @type {boolean}
     * @memberof FrontWebRadioDetailModel
     */
    hasProgram?: boolean;
    /**
     * Barvy v menu
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    linkColor: string;
    /**
     * Gradient od
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    gradientColorFrom: string;
    /**
     * Gradient do
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    gradientColorTo: string;
    /**
     * Obrázek pozadí
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    background: string;
    /**
     * Proklik pozadi
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    backgroundUrl: string;
    /**
     * 
     * @type {Array<FrontWebRadioStreamPlaylistModel>}
     * @memberof FrontWebRadioDetailModel
     */
    qualities: Array<FrontWebRadioStreamPlaylistModel>;
    /**
     * Id
     * @type {number}
     * @memberof FrontWebRadioDetailModel
     */
    id?: number;
    /**
     * Slug
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    slug: string;
    /**
     * Název rádia
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    name: string;
    /**
     * Slogan
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    slogan: string;
    /**
     * Logo rádia
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    logo: string;
    /**
     * Logo rádia (og:)
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    ogLogo: string;
    /**
     * Popis radia (og:)
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    metaDescription: string;
    /**
     * Stránky rádia
     * @type {string}
     * @memberof FrontWebRadioDetailModel
     */
    url: string;
}

/**
 * Check if a given object implements the FrontWebRadioDetailModel interface.
 */
export function instanceOfFrontWebRadioDetailModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "linkColor" in value;
    isInstance = isInstance && "gradientColorFrom" in value;
    isInstance = isInstance && "gradientColorTo" in value;
    isInstance = isInstance && "background" in value;
    isInstance = isInstance && "backgroundUrl" in value;
    isInstance = isInstance && "qualities" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slogan" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "ogLogo" in value;
    isInstance = isInstance && "metaDescription" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function FrontWebRadioDetailModelFromJSON(json: any): FrontWebRadioDetailModel {
    return FrontWebRadioDetailModelFromJSONTyped(json, false);
}

export function FrontWebRadioDetailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebRadioDetailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasDescription': !exists(json, 'hasDescription') ? undefined : json['hasDescription'],
        'hasPodcasts': !exists(json, 'hasPodcasts') ? undefined : json['hasPodcasts'],
        'hasPlaylist': !exists(json, 'hasPlaylist') ? undefined : json['hasPlaylist'],
        'hasLyrics': !exists(json, 'hasLyrics') ? undefined : json['hasLyrics'],
        'hasSimilars': !exists(json, 'hasSimilars') ? undefined : json['hasSimilars'],
        'hasProgram': !exists(json, 'hasProgram') ? undefined : json['hasProgram'],
        'linkColor': json['linkColor'],
        'gradientColorFrom': json['gradientColorFrom'],
        'gradientColorTo': json['gradientColorTo'],
        'background': json['background'],
        'backgroundUrl': json['backgroundUrl'],
        'qualities': ((json['qualities'] as Array<any>).map(FrontWebRadioStreamPlaylistModelFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'slogan': json['slogan'],
        'logo': json['logo'],
        'ogLogo': json['ogLogo'],
        'metaDescription': json['metaDescription'],
        'url': json['url'],
    };
}

export function FrontWebRadioDetailModelToJSON(value?: FrontWebRadioDetailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasDescription': value.hasDescription,
        'hasPodcasts': value.hasPodcasts,
        'hasPlaylist': value.hasPlaylist,
        'hasLyrics': value.hasLyrics,
        'hasSimilars': value.hasSimilars,
        'hasProgram': value.hasProgram,
        'linkColor': value.linkColor,
        'gradientColorFrom': value.gradientColorFrom,
        'gradientColorTo': value.gradientColorTo,
        'background': value.background,
        'backgroundUrl': value.backgroundUrl,
        'qualities': ((value.qualities as Array<any>).map(FrontWebRadioStreamPlaylistModelToJSON)),
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'slogan': value.slogan,
        'logo': value.logo,
        'ogLogo': value.ogLogo,
        'metaDescription': value.metaDescription,
        'url': value.url,
    };
}

