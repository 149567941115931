import type { Action } from "@reduxjs/toolkit";
import { InputAction, ReplaceInputAction} from "./input";

export type PositionAction = Action<"Position"> & {
    payload: number
}

export const PositionReducer = (state:number = 1, action: PositionAction|InputAction|ReplaceInputAction) => {
    if (action.type === "Position") {
        let newState = action.payload;
        if (isNaN(newState))
            newState = 0;

        return newState;
    }
    return state;
}