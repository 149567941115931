import type { Action } from "@reduxjs/toolkit";

export type QualityAction = Action<"QualityAction"> & {
    payload: {
        slug: string,
        url?: string
    }
}

export type InitQualitiesAction = Action<"InitQualities"> & {
    payload: QualityType[],
}

export type QualityType = {
    slug: string,
    url: string,
};

export const QualitiesReducer = (state: QualityType[] = [], action: QualityAction|InitQualitiesAction) => {
    if (action.type === "QualityAction") {
        let newState = [...state];
        if (!action.payload.url) {
            return newState = newState.filter(x => x.slug !== action.payload.slug);
        }
        else
        {
            newState = [{
                slug: action.payload.slug,
                url: action.payload.url
            }].concat(state.filter(x => x.slug !== action.payload.slug));
            return newState.slice(0, 10);
        }
    } else if (action.type === "InitQualities") {
        return [ ...action.payload]
    }
    return state;
}