/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebRadioDescriptionModel
 */
export interface FrontWebRadioDescriptionModel {
    /**
     * O rádiu
     * @type {string}
     * @memberof FrontWebRadioDescriptionModel
     */
    description: string;
    /**
     * O rádiu }(markdown)
     * @type {string}
     * @memberof FrontWebRadioDescriptionModel
     */
    descriptionMarkdown: string;
    /**
     * Mail
     * @type {string}
     * @memberof FrontWebRadioDescriptionModel
     */
    mail: string;
    /**
     * Web
     * @type {string}
     * @memberof FrontWebRadioDescriptionModel
     */
    web: string;
    /**
     * Facebook
     * @type {string}
     * @memberof FrontWebRadioDescriptionModel
     */
    facebook: string;
    /**
     * Instagram
     * @type {string}
     * @memberof FrontWebRadioDescriptionModel
     */
    instagram: string;
    /**
     * Instagram
     * @type {string}
     * @memberof FrontWebRadioDescriptionModel
     */
    telefon: string;
}

/**
 * Check if a given object implements the FrontWebRadioDescriptionModel interface.
 */
export function instanceOfFrontWebRadioDescriptionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "descriptionMarkdown" in value;
    isInstance = isInstance && "mail" in value;
    isInstance = isInstance && "web" in value;
    isInstance = isInstance && "facebook" in value;
    isInstance = isInstance && "instagram" in value;
    isInstance = isInstance && "telefon" in value;

    return isInstance;
}

export function FrontWebRadioDescriptionModelFromJSON(json: any): FrontWebRadioDescriptionModel {
    return FrontWebRadioDescriptionModelFromJSONTyped(json, false);
}

export function FrontWebRadioDescriptionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebRadioDescriptionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'descriptionMarkdown': json['descriptionMarkdown'],
        'mail': json['mail'],
        'web': json['web'],
        'facebook': json['facebook'],
        'instagram': json['instagram'],
        'telefon': json['telefon'],
    };
}

export function FrontWebRadioDescriptionModelToJSON(value?: FrontWebRadioDescriptionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'descriptionMarkdown': value.descriptionMarkdown,
        'mail': value.mail,
        'web': value.web,
        'facebook': value.facebook,
        'instagram': value.instagram,
        'telefon': value.telefon,
    };
}

