import React from 'react';
import './global.scss';
import Layout from "./pages/layout";
import {AppRoutes, ErrorStart} from "./pages/routes";

function App(props: {
  domain: string,
  errorStart?: ErrorStart
}) {
  return <Layout domain={props.domain}>
    <AppRoutes errorStart={props.errorStart} />
  </Layout>
}

export default App;
