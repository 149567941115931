import {createContext, startTransition, useContext, useEffect, useState} from "react";

const Ctx =  createContext({
    noGap: false
});


export const SafariProvider = (props: {
    children: React.ReactNode
}) => {

    const [state, setState] = useState({
        noGap: true
    })

    useEffect(() => {
        startTransition(() => {
            let noGap = !("gap" in (document.createElement("div").style));
            const agent = navigator.userAgent.toLowerCase();
            if (agent.indexOf("safari") > -1)
            {
                let m = agent.match(/version\/([0-9]+)\./);
                if (m && parseInt(m[1]) <= 12) {
                    noGap = true;
                }
            }
            setState({
                noGap
            })
        });

    }, []);

    return <Ctx.Provider value={state}>
        {props.children}
    </Ctx.Provider>
}

export const useIsSafari = () =>{
    return  useContext(Ctx);
}