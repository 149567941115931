"use client";

import {createContext, startTransition, useCallback, useContext, useEffect, useMemo, useState} from "react";

const Context = createContext<{
    message: undefined|null|{
        text: string,
        isError: boolean,
        key: number
    },
    reset: () =>void,
    setMessage: (text: string, isError: boolean) =>void
}>({
    message: null,
    reset: () => {},
    setMessage: () => {}
})

export const useMessage = () => {
    return useContext(Context);
}

export const MessageProvider = (props: {
    children: React.ReactNode,
}) => {

    const [message, setMessage] = useState<null| { text: string, isError: boolean, key: number}>(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const key = useMemo(() => new Date().getTime(), [message]);

    useEffect(() => {
        const cookie = document.cookie || "";

        const hasVal = cookie.split(";").reduce((acc, x) => {
            if (acc)
                return acc;

            const [key, val] = x.split("=");

            if (key.trim() && key.trim() === process.env.REACT_APP_PUBLIC_MESSAGE) {
                return decodeURIComponent(val);
            }
            return acc;
        }, "")

        if (hasVal){
            try {
                const parsed = JSON.parse(hasVal);
                startTransition(() => setMessage({...parsed, key}));
            }
            catch (e) {}
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reset = useCallback(()=> {
        document.cookie = `${process.env.REACT_APP_PUBLIC_MESSAGE!}=none;expires=Thu, 01 Jan 1990 00:00:00 GMT`
        startTransition(() => setMessage(null));
    },[])

    const setCookieMessage = useCallback((text: string, isError: boolean)=>{
        const expires = new Date();
        expires.setTime(expires.getTime() + 8000);
        document.cookie = `${process.env.REACT_APP_PUBLIC_MESSAGE!}=${encodeURIComponent(JSON.stringify({text, isError}))};expires=${expires.toUTCString()};domain=.radia.cz;secure=true;path=/;sameSite=strict`
        startTransition(() => setMessage({ text, isError, key }));

    },[key]);

    const contextValue = useMemo( () => {
        return {
            message,
            reset,
            setMessage: setCookieMessage
        }
    }, [message, reset, setCookieMessage]);

    return <Context.Provider value={contextValue}>
        {props.children}
    </Context.Provider>
}


