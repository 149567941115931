import {Aborted, useFetcher} from "./useFetcher";
import {ApiClient} from "../generated/ApiClient";
import {ResponseError} from "../generated";



export const useUserKey = () => {
    return {
        queryKey: ["user"],
        refetchOnMount: false,
        queryFn: useFetcher( () => new ApiClient().getApiUserCurrent({
            credentials: "include",
            signal: Aborted().signal
        }).catch(e => {
            if (e instanceof ResponseError) {
                if (e.response.status === 401)
                    return null;
            }
            throw e;
        }))
    }
}