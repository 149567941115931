import {Fragment, lazy, Suspense, useEffect, useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import {Loadbar} from "../contexts/useLoader";
import { ErrorBoundary} from "./ErrorBoundary";
import {FallbackError} from "./FallbackError";
import {NotFoundErrorPage} from "./(error)/NotFoundErrorPage";
import {UndefinedErrorPage} from "./(error)/UndefinedErrorPage";
import {ApiClient} from "../generated/ApiClient";
import {Loader} from "../components/Loader";
import {useMessage} from "../contexts/useMessage";

const Homepage = lazy(() => import("./(homepage)"));
const Text = lazy(() => import("./(texty)/:slug"));
const JakNasNaladite = lazy(() => import("./(texty)/jak-nas-naladite"));
const StahnoutAplikaci = lazy(() => import("./(texty)/stahnout-aplikaci"));
const Podcasty = lazy(() => import("./(seznam)/podcasty"))
const PodcastyStyl = lazy(() => import("./(seznam)/podcasty/styl/:slug"))
const Podcast = lazy(() => import("./(seznam)/podcasty/:slug"))
const PodcastEpisode = lazy(() => import("./(seznam)/podcasty/:slug/:episodeId"))
const PodcastEpisodeListen = lazy(() => import("./(seznam)/podcasty/rozposlouchane"))
const Hledani = lazy(() => import("./hledani"))
const HledaniPodcasty = lazy(() => import("./hledani/podcasty"))
const HledaniEpizody = lazy(() => import("./hledani/epizody"))
const HledaniRadia = lazy(() => import("./hledani/radia"))
const RadiaPoslechovot = lazy(()=> import('./(seznam)/radia'));
const RadiaAbecedne = lazy(()=> import('./(seznam)/radia/abecedne'));
const RadiaMoje = lazy(()=> import('./(seznam)/radia/moje'));
const RadiaStyl = lazy(()=> import('./(seznam)/radia/styl'));
const RadiaStylDetail = lazy(()=> import('./(seznam)/radia/styl/:slug'));
const Radio = lazy(() => import("./(seznam)/radia/(radio)/:slug"));
const RadioInfo = lazy(() => import("./(seznam)/radia/(radio)/:slug/info"));
const RadioPlaylist = lazy(() => import("./(seznam)/radia/(radio)/:slug/playlist"));
const RadioPodcasty = lazy(() => import("./(seznam)/radia/(radio)/:slug/podcasty"));
const RadioPodobnaRadia = lazy(() => import("./(seznam)/radia/(radio)/:slug/podobna-radia"));
const RadioProgram = lazy(() => import("./(seznam)/radia/(radio)/:slug/program"));
const RadioPodcast = lazy(() => import("./(seznam)/radia/(radio)/:slug/podcasty/:slugProgram"));
const RadioPodcastEpisode = lazy(() => import("./(seznam)/radia/(radio)/:slug/podcasty/:slugProgram/:episodeId"));
const MujUcet = lazy(() =>import( "./(ucet)/muj-ucet"));
const Registrace = lazy(() => import("./(ucet)/registrace"))
const ZapomenuteHeslo = lazy(() => import("./(ucet)/zapomenute-heslo"));
const NahlasitChypu = lazy(() => import("./(error)/UndefinedErrorPage"));
const Odhlaseni = () => {
    const { setMessage } = useMessage();
    useEffect(() => {
        (async() =>
        {
            try {
                await new ApiClient().getApiUserLogout({
                    credentials: "include"
                });
                setMessage("Byli jste odhlášeni",  false);
            }
            catch (e) {}
            document.location = "/";
        })();
        // eslint-disable-next-line
    }, []);

    return <Loader />
}


const WithError = (props: {
    children: React.ReactNode,
    height?: number
}) => {
    const {pathname, search} = useLocation();
    const [chyba, setChyba] = useState({pathname, search, error: false});

    useEffect(() => {
        if (chyba.error) {
            setChyba({
                pathname,
                search,
                error: false
            })
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [pathname, search]);

    return <Fragment key={`${chyba.pathname}${chyba.search}`}>
            <ErrorBoundary fallback={FallbackError} onError={(err, errorInfo)=> {
                setChyba(c => {
                    return {...c, error: true}
                });
            }}>
                <Suspense fallback={<>
                    <Loadbar loading={true}/>
                    <Loader height={props.height}/>
                </>}>

                    {props.children}
                </Suspense>
            </ErrorBoundary>
    </Fragment>
}

export type ErrorStart = {
    "path+Search": string
    code: number
}


export const AppRoutes = (props: {
    errorStart?: ErrorStart
}) => {

    return <Routes>
        {props.errorStart ? (() => {
            if (props.errorStart?.code === 404)
                return <Route path={props.errorStart["path+Search"]} element={<NotFoundErrorPage />} />
            return <Route path={props.errorStart["path+Search"]} element={<UndefinedErrorPage />} />
        })(): null}
        <Route path="/" element={<WithError><Homepage/></WithError>}/>
        <Route path="/radia" element={<WithError><RadiaPoslechovot/></WithError>}/>
        <Route path="/radia/abecedne" element={<WithError><RadiaAbecedne/></WithError>}/>
        <Route path="/radia/moje" element={<WithError><RadiaMoje/></WithError>}/>
        <Route path="/radia/styl" element={<WithError><RadiaStyl/></WithError>}/>
        <Route path="/radia/styl/:slug" element={<WithError><RadiaStylDetail/></WithError>}/>

        <Route path="/muj-ucet" element={<WithError><MujUcet/></WithError>}/>
        <Route path="/registrace" element={<WithError><Registrace/></WithError>} />

        <Route path="/radia/:slug" element={<WithError><Radio/></WithError>}>
            <Route path="info" element={<WithError height={400}><RadioInfo/></WithError>}/>
            <Route path="playlist" element={<WithError height={400}><RadioPlaylist/></WithError>}/>
            <Route path="podcasty/:slugProgram" element={<WithError height={400}><RadioPodcast/></WithError>}/>
            <Route path="podcasty/:slugProgram/:episodeId" element={<WithError height={400}><RadioPodcastEpisode/></WithError>}/>
            <Route path="podcasty" element={<WithError height={400}><RadioPodcasty/></WithError>}/>
            <Route path="podobna-radia" element={<WithError height={400}><RadioPodobnaRadia/></WithError>}/>
            <Route path="program" element={<WithError height={400}><RadioProgram/></WithError>}/>
        </Route>

        <Route path="/hledani/podcasty" element={<WithError><HledaniPodcasty/></WithError>}/>
        <Route path="/hledani/epizody" element={<WithError><HledaniEpizody/></WithError>}/>
        <Route path="/hledani/radia" element={<WithError><HledaniRadia/></WithError>}/>
        <Route path="/hledani" element={<WithError><Hledani/></WithError>}/>
        <Route path="/podcasty/styl/:slug" element={<WithError><PodcastyStyl/></WithError>}/>
        <Route path="/podcasty/rozposlouchane" element={<WithError><PodcastEpisodeListen/></WithError>}/>

        <Route path="/podcasty/:slug" element={<WithError><Podcast/></WithError>}/>
        <Route path="/podcasty/:slug/:episodeId" element={<WithError><PodcastEpisode/></WithError>}/>
        <Route path="/podcasty" element={<WithError><Podcasty/></WithError>}/>
        <Route path="/jak-nas-naladite" element={<WithError><JakNasNaladite/></WithError>}/>
        <Route path="/stahnout-aplikaci" element={<WithError><StahnoutAplikaci/></WithError>}/>
        <Route path="/odhlasit-se" element={<WithError><Odhlaseni/></WithError>}/>
        <Route path="/zapomenute-heslo" element={<WithError><ZapomenuteHeslo /></WithError>} />
        <Route path="/nahlasit-chybu" element={<WithError><NahlasitChypu reportError={true}/></WithError>} />
        <Route path="/:slug"  element={<WithError><Text/></WithError>}/>
        <Route path={"*"} element={<WithError><NotFoundErrorPage /></WithError>} />
    </Routes>
}