
export type WaitingTime = {
    advloadtime: number,
    advwaitingtime: number,
    medialoadtime: number,
    filterplayedstream: number,
    filternotplayedstream: number
}

export const WaitingTimeReducer = (state = {
    advloadtime: 6000,
    advwaitingtime: 10000,
    medialoadtime: 30000,
    filternotplayedstream: 2*60 * 1000,
    filterplayedstream: 30 * 1000,
} as WaitingTime) => {
    return state;
}