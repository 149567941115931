/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebAdvertisementModel } from './FrontWebAdvertisementModel';
import {
    FrontWebAdvertisementModelFromJSON,
    FrontWebAdvertisementModelFromJSONTyped,
    FrontWebAdvertisementModelToJSON,
} from './FrontWebAdvertisementModel';

/**
 * 
 * @export
 * @interface FrontWebAdvertisementsModel
 */
export interface FrontWebAdvertisementsModel {
    /**
     * Triton id
     * @type {string}
     * @memberof FrontWebAdvertisementsModel
     */
    tritonId?: string;
    /**
     * Reklamy
     * @type {Array<FrontWebAdvertisementModel>}
     * @memberof FrontWebAdvertisementsModel
     */
    advertisements: Array<FrontWebAdvertisementModel>;
}

/**
 * Check if a given object implements the FrontWebAdvertisementsModel interface.
 */
export function instanceOfFrontWebAdvertisementsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "advertisements" in value;

    return isInstance;
}

export function FrontWebAdvertisementsModelFromJSON(json: any): FrontWebAdvertisementsModel {
    return FrontWebAdvertisementsModelFromJSONTyped(json, false);
}

export function FrontWebAdvertisementsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebAdvertisementsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tritonId': !exists(json, 'tritonId') ? undefined : json['tritonId'],
        'advertisements': ((json['advertisements'] as Array<any>).map(FrontWebAdvertisementModelFromJSON)),
    };
}

export function FrontWebAdvertisementsModelToJSON(value?: FrontWebAdvertisementsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tritonId': value.tritonId,
        'advertisements': ((value.advertisements as Array<any>).map(FrontWebAdvertisementModelToJSON)),
    };
}

