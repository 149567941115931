/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontApplicationInputGdprModel
 */
export interface FrontApplicationInputGdprModel {
    /**
     * Souhlas s gdpr
     * @type {boolean}
     * @memberof FrontApplicationInputGdprModel
     */
    agree: boolean;
}

/**
 * Check if a given object implements the FrontApplicationInputGdprModel interface.
 */
export function instanceOfFrontApplicationInputGdprModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agree" in value;

    return isInstance;
}

export function FrontApplicationInputGdprModelFromJSON(json: any): FrontApplicationInputGdprModel {
    return FrontApplicationInputGdprModelFromJSONTyped(json, false);
}

export function FrontApplicationInputGdprModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationInputGdprModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agree': json['agree'],
    };
}

export function FrontApplicationInputGdprModelToJSON(value?: FrontApplicationInputGdprModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agree': value.agree,
    };
}

