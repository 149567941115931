import {useRadioRecommendedKey} from "../../queriesKeys/useRadioRecommendedKey";
import {useQuery} from "@tanstack/react-query";
import {RadioSlider} from "../../components/List/Radio/RadioSlider";
import {TuneOn} from "../../components/Footer/TuneOn";
import {lazy, startTransition, Suspense, useEffect, useState} from "react";
import {Loadbar} from "../../contexts/useLoader";

const ErrorForm = lazy(() => import("../../components/Form/ErrorForm"))
export const NotFoundErrorPage = () => {

    let recommended = useQuery({
        ...useRadioRecommendedKey(true)
    });

    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        startTransition(() => setShowForm(true));
    }, []);

    return <main className={"main"}>
        <div className={"error-header mb-48px"}>

            <div style={{width: '300px', height: '300px', marginLeft: 'auto', marginRight: 'auto', display: "block"}}>
                <iframe title={"NotFound"} style={{width: '300px', height: '300px'}}
                        src="https://lottie.host/embed/03729c8e-9f33-44ea-8496-2e7894ff2a8e/r1ooyUYwvq.json"></iframe>
            </div>

            <h1 className={"text-center  mt-36px mb-24px"}>
                Jejda! 404
            </h1>
            <div className={"text-center fw-bold opacity-60  mb-24px"}>
                Stránka nebo soubor, který se snažíte otevřít, pravděpodobně neexistuje.<br/>
                Omlouváme se za případné nepříjemnosti.
            </div>
            <div className={"text-center  mb-24px"}>
                <a href={"/"} className={"btn btn-radiacz pms-48px pme-48px"}>
                    Domovská stránka
                </a>
            </div>

        </div>
        {showForm ?
        <>
            <hr className={"mb-48px"}/>
            <div className={"pms-24px"}>
                <p className={"text-center mb-36px"}>Pokud si myslíte, že to, co hledáte, tady opravdu má být a chcete nám
                    pomoci,<br/>
                    popište prosím do následujícího formuláře, jak jste se sem dostal(a) a co jste očekával(a) místo této
                    chyby.</p>

                <Suspense fallback={<Loadbar loading={true} />}>
                    <ErrorForm email={""}/>
                </Suspense>

            </div>
        </> : null}
        {recommended.data?.length ?
            <>
                <hr className={"unmain mt-36px mb-24px"}/>
                <RadioSlider title={"Doporučená"} items={recommended.data}/>
            </> : false}
        <hr className={"unmain mt-36px mb-24px"}/>
        <TuneOn />
    </main>
}