import {useEffect, useRef, useState} from "react";
import styles from "./Header.module.scss";
import { useMessage } from "../../contexts/useMessage";

import closeimg from "../../static/close.svg";
const InfoInner = (props: {
    message: string,
    isError: boolean,
    reset: ()=>void
}) => {
    const ref = useRef<HTMLDivElement|null>( null );
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (ref.current) {
            const size = ref.current.getBoundingClientRect();
            ref.current.style.height = size.height + "px";
            const timeout = setTimeout(() => {
                setHide(true);
                props.reset();
            }, 6000);
            return () => {
                clearTimeout(timeout);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.message, props.reset]);

    return <div ref={ref} className={`position-relative ${hide  ? styles.hide : ""} ${styles.message} ${props.isError ? styles.error : ""}`}>
        <button className={"float-end"} onClick={_=>{
            setHide(true);
            props.reset();
        }} >{!hide ? <img alt="" src={closeimg} />: null}</button>
        <div>{props.message}</div>
    </div>;
}

export const Info = () => {

    const { message, reset} = useMessage();
    const [ savedMessage, setSavedMessage ] = useState(message);

    useEffect(() => {
        if (!savedMessage && message) {
            setSavedMessage(message);
        } else if (savedMessage && !message) {
            const timeout = setTimeout(()=>{
                setSavedMessage(null);
            },1000);
            return () => clearTimeout(timeout);
        } else if (savedMessage !== message) {
            setSavedMessage(message);
        }
    }, [message, savedMessage]);

    return savedMessage ? <InfoInner key={savedMessage.key} message={savedMessage.text} isError={savedMessage.isError} reset={reset}/> : null;
}