/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebAdvertisementsModel } from './FrontWebAdvertisementsModel';
import {
    FrontWebAdvertisementsModelFromJSON,
    FrontWebAdvertisementsModelFromJSONTyped,
    FrontWebAdvertisementsModelToJSON,
} from './FrontWebAdvertisementsModel';

/**
 * 
 * @export
 * @interface FrontWebDetailPodcastEpisodeModel
 */
export interface FrontWebDetailPodcastEpisodeModel {
    /**
     * Url záznamu
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    mediaUrl: string;
    /**
     * Typ záznamu
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    mediaType: string;
    /**
     * 
     * @type {FrontWebAdvertisementsModel}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    advertisements: FrontWebAdvertisementsModel;
    /**
     * Id
     * @type {number}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    id: number;
    /**
     * Duration in second
     * @type {number}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    duration: number;
    /**
     * Image
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    image: string;
    /**
     * Name
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    name: string;
    /**
     * PubDate
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    pubDate?: string;
    /**
     * Description
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    description?: string;
    /**
     * Program slug
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    programSlug: string;
    /**
     * Název programu
     * @type {string}
     * @memberof FrontWebDetailPodcastEpisodeModel
     */
    programName: string;
}

/**
 * Check if a given object implements the FrontWebDetailPodcastEpisodeModel interface.
 */
export function instanceOfFrontWebDetailPodcastEpisodeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mediaUrl" in value;
    isInstance = isInstance && "mediaType" in value;
    isInstance = isInstance && "advertisements" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "programSlug" in value;
    isInstance = isInstance && "programName" in value;

    return isInstance;
}

export function FrontWebDetailPodcastEpisodeModelFromJSON(json: any): FrontWebDetailPodcastEpisodeModel {
    return FrontWebDetailPodcastEpisodeModelFromJSONTyped(json, false);
}

export function FrontWebDetailPodcastEpisodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebDetailPodcastEpisodeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mediaUrl': json['mediaUrl'],
        'mediaType': json['mediaType'],
        'advertisements': FrontWebAdvertisementsModelFromJSON(json['advertisements']),
        'id': json['id'],
        'duration': json['duration'],
        'image': json['image'],
        'name': json['name'],
        'pubDate': !exists(json, 'pubDate') ? undefined : json['pubDate'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'programSlug': json['programSlug'],
        'programName': json['programName'],
    };
}

export function FrontWebDetailPodcastEpisodeModelToJSON(value?: FrontWebDetailPodcastEpisodeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mediaUrl': value.mediaUrl,
        'mediaType': value.mediaType,
        'advertisements': FrontWebAdvertisementsModelToJSON(value.advertisements),
        'id': value.id,
        'duration': value.duration,
        'image': value.image,
        'name': value.name,
        'pubDate': value.pubDate,
        'description': value.description,
        'programSlug': value.programSlug,
        'programName': value.programName,
    };
}

