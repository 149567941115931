/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontApplicationUserLoggedModel
 */
export interface FrontApplicationUserLoggedModel {
    /**
     * OK
     * @type {boolean}
     * @memberof FrontApplicationUserLoggedModel
     */
    success: boolean;
    /**
     * Access token
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    token: string;
    /**
     * Access token
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    accessToken: string;
    /**
     * Refresh token
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    refreshToken: string;
    /**
     * Doba vypršení access tokenu
     * @type {number}
     * @memberof FrontApplicationUserLoggedModel
     */
    expireIn: number;
    /**
     * Doba vypršení access tokenu
     * @type {number}
     * @memberof FrontApplicationUserLoggedModel
     */
    expiresIn: number;
    /**
     * Souhlas s gdpr
     * @type {boolean}
     * @memberof FrontApplicationUserLoggedModel
     */
    gdpr: boolean;
    /**
     * Typ tokenu
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    tokenType?: string;
    /**
     * scope
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    scope?: string;
    /**
     * Mail
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    email: string;
    /**
     * Facebook id
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    facebookId: string;
    /**
     * Google id
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    googleId: string;
    /**
     * Apple id
     * @type {string}
     * @memberof FrontApplicationUserLoggedModel
     */
    appleId: string;
    /**
     * ID rádií
     * @type {Array<number>}
     * @memberof FrontApplicationUserLoggedModel
     */
    favourites: Array<number>;
    /**
     * Apple id
     * @type {number}
     * @memberof FrontApplicationUserLoggedModel
     */
    id: number;
}

/**
 * Check if a given object implements the FrontApplicationUserLoggedModel interface.
 */
export function instanceOfFrontApplicationUserLoggedModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "expireIn" in value;
    isInstance = isInstance && "expiresIn" in value;
    isInstance = isInstance && "gdpr" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "facebookId" in value;
    isInstance = isInstance && "googleId" in value;
    isInstance = isInstance && "appleId" in value;
    isInstance = isInstance && "favourites" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function FrontApplicationUserLoggedModelFromJSON(json: any): FrontApplicationUserLoggedModel {
    return FrontApplicationUserLoggedModelFromJSONTyped(json, false);
}

export function FrontApplicationUserLoggedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationUserLoggedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'token': json['token'],
        'accessToken': json['access_token'],
        'refreshToken': json['refresh_token'],
        'expireIn': json['expire_in'],
        'expiresIn': json['expires_in'],
        'gdpr': json['gdpr'],
        'tokenType': !exists(json, 'token_type') ? undefined : json['token_type'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'email': json['email'],
        'facebookId': json['facebook_id'],
        'googleId': json['google_id'],
        'appleId': json['apple_id'],
        'favourites': json['favourites'],
        'id': json['id'],
    };
}

export function FrontApplicationUserLoggedModelToJSON(value?: FrontApplicationUserLoggedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'token': value.token,
        'access_token': value.accessToken,
        'refresh_token': value.refreshToken,
        'expire_in': value.expireIn,
        'expires_in': value.expiresIn,
        'gdpr': value.gdpr,
        'token_type': value.tokenType,
        'scope': value.scope,
        'email': value.email,
        'facebook_id': value.facebookId,
        'google_id': value.googleId,
        'apple_id': value.appleId,
        'favourites': value.favourites,
        'id': value.id,
    };
}

