import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";


const searchSlice = createSlice( {
    name: "search",
    initialState: {
        value: "",
    } as SearchModel,
    reducers: {
        setSearch : (state, action: PayloadAction<string> ) => {
            const newState = { ...state };
            newState.value = action.payload;
            return newState;
        },
        resetSearch: (state) => {
            const newState = { ...state };
            newState.value = "";
            return newState;
        }
    }
})


export const { setSearch, resetSearch } = searchSlice.actions;

export default searchSlice.reducer;