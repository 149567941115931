import type { Action } from "@reduxjs/toolkit";
import type {VastResponse} from "vast-client";
import {Media} from "./types";
import {StatusAction} from "./status";

export type None = {
    type: "None"
}

export type Ping = {
    type: "Ping"
}

export type AdvertisementVast = {
    id: number,
    type: "AdvertisementVast",
    content: VastResponse,
    hours?: number
}

export type Advertisement =  AdvertisementVast |  false;

export type Audio = {
    type: "Audio",
    content: Media
}

export type Dash = {
    type: "Dash",
    content: Dash
}

export type Source = Audio |  AdvertisementVast | None | Ping;

export type SourceAction = Action<"Source"> & {
    payload: Source,
}

export const SourceReducer = (state: Source = { type: "None" }, action: SourceAction|StatusAction) => {
    switch (action.type) {
        case 'Source':
            return action.payload;
        case 'Status':
            if (action.payload === "STOPPED") {
                return {
                    type: "None"
                } as None
            }
    }
    return state;
}