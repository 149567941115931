import {FrontWebHeaderModel} from "../generated";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";

export const PageHeader = (props: {

}) => {

    const { pathname } = useLocation();

    const { data, isLoading } = useQuery({
        queryKey: ["header", pathname],
        retryOnMount: false,
        queryFn: async () => {
            const p = pathname && pathname !== "/" ? pathname : "/index";
            const resp = await fetch(`${process.env.REACT_APP_API_DOMAIN!}/api/v1/header/${p.replace(/^\/+/, "")}.json`)
            if (resp.status === 200) {
                return await resp.json() as FrontWebHeaderModel;
            }
            return null;
        }
    });



    useEffect(() => {
        if (isLoading)
            return;
        if (!data)
            return;
        const head = document.head;

        const createMeta = (property: string, value?: string) => {
            Array.from(head.getElementsByTagName("meta"))
                .filter(x => x.getAttribute("property") === property)
                .forEach(x => head.removeChild(x));
            if (value) {
                const meta = document.createElement("meta");
                meta.setAttribute("property", property);
                meta.content = value;
                head.appendChild(meta);
            }

        }

        ["title", "url", "image", "description", "siteName"].forEach(x => {
            switch (x) {
                case "title":
                    const title = Array.from(head.getElementsByTagName("title"))[0] ?? document.createElement("title");
                    title.text = data.title;
                    if (!title.parentNode)
                        head.appendChild(title);
                    break;
                case "description":
                    createMeta("description", data.description);
                    createMeta("og:description", data.description);
                    break;
                case "image":
                    createMeta("og:image", data.image);
                    break;
                case "siteName":
                    createMeta("og:site_name", data.image);
                    break;
                case "url":
                    createMeta("og:canonical", data.url);
                    break;


            }
        })

    }, [data, isLoading]);

    return null;

}