import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../Store";
import {lazy, Suspense, useCallback, useEffect, useState} from "react";
import {InputAction} from "./state/input";
import {StatusRequiredAction} from "./state/status";


import {setPlayedItem} from "../Store/playedItem";
import {FrontWebPodcastEpisodeModel, FrontWebRadioModel} from "../../generated";

import { usePlayItemCreator } from "../../contexts/useRadio";
import { slugifyPath} from "../../utils/slugifyPath";
import {slugifyText} from "../../utils/slugifyText";

export const usePlayOrStop = (item: FrontWebRadioModel|FrontWebPodcastEpisodeModel|PlayedItemType|null) => {

    const playedItemCreator = usePlayItemCreator();

    const dispatch = useDispatch<AppDispatch>();
    const run = useSelector((app: RootState) => app.status);
    const input = useSelector((app: RootState) => app.input);
    const playedItem = item ? playedItemCreator(item) : null;
    const slug = playedItem ? slugifyPath(playedItem) : null;
    const isMouted = useSelector((app:RootState) => app.mount);
    const isSaga = useSelector((app: RootState) => app.saga);

    return {
        play: useCallback(() => {
                if (!playedItem || !slug)
                    return;
                if (!isMouted || !isSaga)
                    return;

                if (slug !== input?.slug) {
                    dispatch(setPlayedItem(playedItem));
                    dispatch({
                        type: "Input",
                        payload: {
                            slug: slug
                        }
                    } as InputAction);

                    if (run === "STOPPED") {
                        dispatch({
                            type: "RequiredStatus",
                            payload: "PLAY"
                        } as StatusRequiredAction)
                    }

                    try {
                        if ("id" in playedItem)
                        {
                            // @ts-ignore
                            gtag("event", {
                                "event_category": "clickPlay",
                                "event_label": "programy",
                                "value": `${playedItem.programSlug}/${playedItem.id}-${slugifyText(playedItem.name)}`
                            });
                        } else {
                            // @ts-ignore
                            gtag("event", {
                                "event_category": "clickPlay",
                                "event_label": "radia",
                                "value": `${slugifyText(playedItem.radioSlug)}`
                            });
                        }
                    }
                    catch(exp)
                    {

                    }

                } else {
                    dispatch({
                        type: "RequiredStatus",
                        payload: run === "STOPPED" ? "PLAY" : "STOP"
                    } as StatusRequiredAction);
                }
           // eslint-disable-next-line
        }, [slug, input?.slug || "", run, isMouted, isSaga]),
        isPlaying: () => {
            return run !== "STOPPED" && input && input?.slug === slug;
        },
        isLoading: () => {
            return run === "LOADING" && input && input?.slug === slug;
        }
    };
}

const PlayerImport = lazy(() => import ("./player"));


export const Player = () => {
    const [inClient, setInClient] = useState(false);
    useEffect(() => {
        setInClient(true);
    }, []);
    if (inClient)
        return <Suspense><PlayerImport /></Suspense>
    return null;
}