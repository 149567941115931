"use client";

import {useMemo, } from "react";
import { useLocation } from "react-router-dom";
import {Advertisement} from "./Advertisement";

export const  AdformMobilni  = () => {

    const { pathname, search} = useLocation();

    const key = useMemo(() => {
        return new Date().getTime();
        // eslint-disable-next-line
    }, [pathname, search]);

    return <Advertisement key={key} id={"adformMobilniBranding"} visible={false} />;
}
