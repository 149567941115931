import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const playedItemSlice = createSlice( {
    name: "playedItem",
    initialState: null as PlayedItemType | null,
    reducers: {
        setPlayedItem: (state, value:PayloadAction<PlayedItemType>) =>{
            return value.payload;
        }
    }
});

export const { setPlayedItem } = playedItemSlice.actions;

export default playedItemSlice.reducer;
