import {MutableRefObject, ReactNode, useCallback, useEffect, useRef} from "react";

export const useClickOuter = (div: MutableRefObject<HTMLDivElement|null>, outClick: () => void ) => {
    useEffect(() => {
        if (div.current === null) {

            return;
        }
        let clicked = false;
        const winclick = (event: MouseEvent) => {

            if (!clicked) {
                outClick();
            }

            clicked = false;
        }
        const divclick = (event: MouseEvent) => {

            clicked = true;
        }
        const cur = div.current;
        if (cur) {
            cur.addEventListener("click", divclick);
            window.addEventListener("click", winclick);
            return () => {
                window.removeEventListener("click", winclick);
                cur.removeEventListener("click", divclick);
            }
        }
    }, [div, outClick]);
}


export const ClickOuterDiv = (props: { children: ReactNode, onOutClick:()=>void }) => {
    const divRef = useRef<HTMLDivElement|null>(null);
    const rOut = useRef(props.onOutClick);

    rOut.current = props.onOutClick

    useClickOuter(divRef, useCallback(() =>{
        rOut.current();
    },[]));

    return <div ref={divRef}>
        {props.children}
    </div>
}