

import { RouteLink} from "../RouteLink";
import styles from "./Header.module.scss";
import { LazySearch} from "../Searchbox";
import {useEffect, useRef, useState} from "react";
import routes from "../../utils/routes";
import {TopViewPlayer} from "../Player/view/Top";

import { RootState} from "../Store";
import {useSelector} from "react-redux";
import {HeaderLink} from "./HeaderLink";
import {Info} from "./Info";
import {SmallUserMenu, UserMenu} from "./User";
import {useLocation} from "react-router-dom";

import closeimg from "../../static/close.svg";
import lupaimg from "../../static/lupa.svg";
import rectanglewhiteimg from '../../static/rectanglewhite.svg';
import radiaczimg from '../../static/radiacz.svg';
import zebrikwhiteimg from "../../static/zebrikwhite.svg";



export const Header = () => {

    const [showSearch, setShowSearch] = useState(false);
    const [small, setSmall] = useState(() => {
        return false;
    });
    const [showMenu, setShowMenu] = useState(false);
    const [rerender, setRerender] = useState(false);
    const headerRef = useRef<HTMLDivElement|null>(null);
    const pathName = useLocation().pathname;
    const hasCurrentUser = useSelector((x: RootState) => x.currentUser);

    useEffect(() => {

    }, []);

    useEffect(() => {

        let small = false;
        const resize = () => {
            const width = headerRef.current?.getBoundingClientRect().width ?? 1024;
            if (width < 850) {
                if (!small) {
                    small = true;
                    setSmall(true);
                    setShowMenu(false);
                }
            } else {
                if (small) {
                    small = false;
                    setSmall(false);
                    setShowMenu(false);
                }
            }
        }

        resize();
        setRerender(true);

        window.addEventListener("resize", resize);
        return () => window.removeEventListener("resize", resize);

    }, []);

    useEffect(() => {
        setShowMenu(false);
    }, [pathName]);


    const urls = [
        {
            text: "Moje oblíbená",
            href: routes.seznam_radii_moje()
        },
        {
            href: routes.seznam_radii_nejposlouchanejsi(),
            text: "Seznam rádií",
        },
        {
            text: "Podcasty",
            href: routes.seznam_podcastu()
        },
        {
            href: "/stahnout-aplikaci",
            text: "Stáhnout aplikaci",
        }
    ];

    const smallUrls = urls.concat([
        {
            text: hasCurrentUser ? `Nastavení účtu` : "Přihlásit se",
            href: "/muj-ucet"
        }
    ]);
    if (hasCurrentUser) {
        smallUrls.push({
            text: "Odhlásit se",
            href: "/odhlasit-se"
        })
    }

    return <>
        <div ref={headerRef} className={`d-flex justify-content-between align-content-center  flex-wrap ${styles.head}`}>
            {small ? <>
                    <RouteLink to={routes.search()} className={`ms-4 position-relative align-self-center ${styles.w24}`}
                          onClick={(e => {
                              e.preventDefault();
                              setShowSearch(!showSearch)
                          })}>
                        <div className={`${pathName === "/" ? styles.searchHomepageHide : ""} text-center`}>
                            <img src={showSearch ? closeimg : lupaimg} alt={showSearch ? 'zavřít' : 'hledat'}/>
                            {showSearch ? <img alt={""} className={styles.rectangle} src={rectanglewhiteimg}/> : false}
                        </div>
                    </RouteLink>
                    <RouteLink to={'/'} className={`d-flex ${styles.sizeItem} ${styles.cursor} align-self-center`} style={{
                        cursor: "pointer"
                    }}>
                        <img src={radiaczimg} className={styles.cursor} width={146} height={32} style={{
                            marginTop: '0px',
                        }} alt={"radia.cz"}/>
                    </RouteLink>
                    <RouteLink to={"#"} className={`me-4 align-self-center text-center ${styles.w24}`} onClick={(e) => {
                        e.preventDefault();
                        setShowMenu(!showMenu);
                    }}>
                        <img alt={"menu"} src={!showMenu ? zebrikwhiteimg: closeimg}/>
                    </RouteLink>
                </> :
                <>
                    <RouteLink  to={'/'} className={`me-2 d-flex ms-5 ${styles.sizeItem} ${styles.cursor} `}>
                        <img alt={"radia.cz"} src={radiaczimg} className={styles.cursor } width={146} height={32} style={{
                            marginTop: '0px',

                        }}/>
                    </RouteLink>

                    <ul className={"nav px-2"}>
                        {urls.map(x => (
                            <li key={x.href} className={"nav-item"}>
                                <HeaderLink href={x.href}
                                            className={`nav-link ${styles.link}`}>
                                    {x.text}
                                </HeaderLink>
                            </li>
                        ))}
                    </ul>
                    {rerender ? <div
                        className={`ms-2 d-flex justify-content-end align-content-center flex-wrap me-5 ${styles.padding6}`}>
                        <div className={`me-24px position-relative ${pathName === "/" ? styles.searchHomepageHide : ""}`}>
                            <RouteLink to={routes.search()}
                                  className={`${styles.w24} text-center `}
                                  onClick={(e => {
                                      e.preventDefault();
                                      setShowSearch(!showSearch)
                                  })}>
                                <img alt="menu" src={showSearch ? closeimg : lupaimg}/>
                                {showSearch ?
                                    <img alt={""} className={`${styles.rectangle}`} src={rectanglewhiteimg}/> : false}
                            </RouteLink>
                        </div>
                        <UserMenu/>
                    </div> : false}
                </>}
            {showSearch ? <LazySearch enableScroll={true} onHide={() => {
                setShowSearch(false);
            }}/> : false}
            {showMenu ?
                <ul className={`nav px-2 d-flex flex-column align-items-center ${styles.smallMenu}`}>
                    {urls.map(x => (
                        <li key={x.href} className={"nav-item"}>
                            <HeaderLink href={x.href}
                                        className={`nav-link ${styles.link}`}>
                                {x.text}
                            </HeaderLink>
                        </li>
                    ))}
                    <SmallUserMenu/>
                </ul>
                : false}

        </div>
        <span className={styles.headFixed}/>
        <TopViewPlayer/>
        <Info/>
    </>;

}
