/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebPodcastEpisodeModel
 */
export interface FrontWebPodcastEpisodeModel {
    /**
     * Id
     * @type {number}
     * @memberof FrontWebPodcastEpisodeModel
     */
    id: number;
    /**
     * Duration in second
     * @type {number}
     * @memberof FrontWebPodcastEpisodeModel
     */
    duration: number;
    /**
     * Image
     * @type {string}
     * @memberof FrontWebPodcastEpisodeModel
     */
    image: string;
    /**
     * Name
     * @type {string}
     * @memberof FrontWebPodcastEpisodeModel
     */
    name: string;
    /**
     * PubDate
     * @type {string}
     * @memberof FrontWebPodcastEpisodeModel
     */
    pubDate: string;
    /**
     * Description
     * @type {string}
     * @memberof FrontWebPodcastEpisodeModel
     */
    description?: string;
    /**
     * Program slug
     * @type {string}
     * @memberof FrontWebPodcastEpisodeModel
     */
    programSlug: string;
    /**
     * Název programu
     * @type {string}
     * @memberof FrontWebPodcastEpisodeModel
     */
    programName: string;
}

/**
 * Check if a given object implements the FrontWebPodcastEpisodeModel interface.
 */
export function instanceOfFrontWebPodcastEpisodeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "pubDate" in value;
    isInstance = isInstance && "programSlug" in value;
    isInstance = isInstance && "programName" in value;

    return isInstance;
}

export function FrontWebPodcastEpisodeModelFromJSON(json: any): FrontWebPodcastEpisodeModel {
    return FrontWebPodcastEpisodeModelFromJSONTyped(json, false);
}

export function FrontWebPodcastEpisodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebPodcastEpisodeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'duration': json['duration'],
        'image': json['image'],
        'name': json['name'],
        'pubDate': json['pubDate'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'programSlug': json['programSlug'],
        'programName': json['programName'],
    };
}

export function FrontWebPodcastEpisodeModelToJSON(value?: FrontWebPodcastEpisodeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'duration': value.duration,
        'image': value.image,
        'name': value.name,
        'pubDate': value.pubDate,
        'description': value.description,
        'programSlug': value.programSlug,
        'programName': value.programName,
    };
}

