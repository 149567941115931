/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebPodcastProgramModel } from './FrontWebPodcastProgramModel';
import {
    FrontWebPodcastProgramModelFromJSON,
    FrontWebPodcastProgramModelFromJSONTyped,
    FrontWebPodcastProgramModelToJSON,
} from './FrontWebPodcastProgramModel';

/**
 * 
 * @export
 * @interface FrontWebSearchResultProgramsModel
 */
export interface FrontWebSearchResultProgramsModel {
    /**
     * Počet stránek
     * @type {number}
     * @memberof FrontWebSearchResultProgramsModel
     */
    pages: number;
    /**
     * Celkový počet
     * @type {number}
     * @memberof FrontWebSearchResultProgramsModel
     */
    count: number;
    /**
     * Epizody
     * @type {Array<FrontWebPodcastProgramModel>}
     * @memberof FrontWebSearchResultProgramsModel
     */
    result: Array<FrontWebPodcastProgramModel>;
}

/**
 * Check if a given object implements the FrontWebSearchResultProgramsModel interface.
 */
export function instanceOfFrontWebSearchResultProgramsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function FrontWebSearchResultProgramsModelFromJSON(json: any): FrontWebSearchResultProgramsModel {
    return FrontWebSearchResultProgramsModelFromJSONTyped(json, false);
}

export function FrontWebSearchResultProgramsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebSearchResultProgramsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pages': json['pages'],
        'count': json['count'],
        'result': ((json['result'] as Array<any>).map(FrontWebPodcastProgramModelFromJSON)),
    };
}

export function FrontWebSearchResultProgramsModelToJSON(value?: FrontWebSearchResultProgramsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pages': value.pages,
        'count': value.count,
        'result': ((value.result as Array<any>).map(FrontWebPodcastProgramModelToJSON)),
    };
}

