/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebStatisticsPlayerModel
 */
export interface FrontWebStatisticsPlayerModel {
    /**
     * Uuid
     * @type {string}
     * @memberof FrontWebStatisticsPlayerModel
     */
    uuid: string;
    /**
     * State
     * @type {string}
     * @memberof FrontWebStatisticsPlayerModel
     */
    state: string;
    /**
     * Station
     * @type {number}
     * @memberof FrontWebStatisticsPlayerModel
     */
    station?: number;
    /**
     * Mobil
     * @type {boolean}
     * @memberof FrontWebStatisticsPlayerModel
     */
    isMobile?: boolean;
}

/**
 * Check if a given object implements the FrontWebStatisticsPlayerModel interface.
 */
export function instanceOfFrontWebStatisticsPlayerModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function FrontWebStatisticsPlayerModelFromJSON(json: any): FrontWebStatisticsPlayerModel {
    return FrontWebStatisticsPlayerModelFromJSONTyped(json, false);
}

export function FrontWebStatisticsPlayerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebStatisticsPlayerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'state': json['state'],
        'station': !exists(json, 'station') ? undefined : json['station'],
        'isMobile': !exists(json, 'isMobile') ? undefined : json['isMobile'],
    };
}

export function FrontWebStatisticsPlayerModelToJSON(value?: FrontWebStatisticsPlayerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'state': value.state,
        'station': value.station,
        'isMobile': value.isMobile,
    };
}

