import type { Action } from "@reduxjs/toolkit";

export type PositionsAction = Action<"Positions"> & {
    payload: {
        slug: string,
        time: number,
        when?: string,
        save: boolean
    }
}

export type InitPositionsAction = Action<"InitPositions"> & {
    payload: PositionType[],
}

export type PositionType = {
    slug: string,
    time: number,
};

export const PositionsReducer = (state: PositionType[] = [], action: PositionsAction|InitPositionsAction) => {
    if (action.type === "Positions") {
        let newState = [...state];
        if (action.payload.time <= 0) {
            return newState = newState.filter(x => x.slug !== action.payload.slug);
        }
        else
        {
            newState = [{
                slug: action.payload.slug,
                time: action.payload.time
            }].concat(state.filter(x => x.slug !== action.payload.slug));
            return newState.slice(0, 10);
        }
    } else if (action.type === "InitPositions") {
        return [ ...action.payload]
    }
    return state;
}