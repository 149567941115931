import type { Action } from "@reduxjs/toolkit";


export type AudioAdvertisementTimingAction = Action<"AudioAdvertisementTiming"> & {
    payload: AudioAdvertisementTiming[]
}
export type AudioAdvertisementTimingAddAction = Action<"AudioAdvertisementTimingAdd"> & {
    payload: {
        id: number,
        validTo: number,
        slug: string
    }
}

export type AudioAdvertisementTiming = {
    id: number,
    validTo: number,
    slug: string
}

export const AudioAdvertisementTimingReducer = (state:AudioAdvertisementTiming[] = [], action: AudioAdvertisementTimingAction) => {
    if (action.type !== "AudioAdvertisementTiming")
        return state ?? [];
    return action.payload ?? [];
}