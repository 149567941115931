import {StatusReducer} from "./status";
import {SourceReducer} from "./source";
import {InputReducer} from "./input";
import {VolumeReducer} from "./volume";
import {MaxSizeReducer} from "./maxsize";
import {IdReducer} from "./id";
import {GdprReducer} from "./gdpr";
import { WaitingTimeReducer} from "./waitingtime";
import {InstanceReducer} from "./instance";
import {AudioAdvertisementTimingReducer} from "./audioadvertisementtiming";
import { PositionReducer } from "./position";
import {PositionsReducer} from "./positions";
import {SpeedReducer} from "./speed";
import {QualitiesReducer} from "./qualities";
import {MountReducer} from "./mount";
import {SagaReducer} from "./saga";

const PlayerReducer = {
    status: StatusReducer,
    source: SourceReducer,
    input: InputReducer,
    volume: VolumeReducer,
    maxSize: MaxSizeReducer,
    id: IdReducer,
    gdpr: GdprReducer,
    position: PositionReducer,
    positions: PositionsReducer,
    waitingTime: WaitingTimeReducer,
    instance: InstanceReducer,
    audioAdvertisementTiming: AudioAdvertisementTimingReducer,
    speed: SpeedReducer,
    qualities: QualitiesReducer,
    mount: MountReducer,
    saga: SagaReducer
}

export default PlayerReducer;

export type AppState = {
    audioAdvertisementTiming: ReturnType<typeof AudioAdvertisementTimingReducer>
    status: ReturnType<typeof StatusReducer>,
    source: ReturnType<typeof SourceReducer>,
    input: ReturnType<typeof InputReducer>,
    volume: ReturnType<typeof VolumeReducer>,
    position: ReturnType<typeof PositionReducer>,
    positions: ReturnType<typeof PositionsReducer>,
    maxSize: ReturnType<typeof MaxSizeReducer>,
    id: ReturnType<typeof IdReducer>,
    gdpr: ReturnType<typeof GdprReducer>,
    waitingTime: ReturnType<typeof WaitingTimeReducer>
    instance: ReturnType<typeof InstanceReducer>
    speed: ReturnType<typeof SpeedReducer>
    qualities: ReturnType<typeof QualitiesReducer>
    mount: ReturnType<typeof MountReducer>,
    saga: ReturnType<typeof SagaReducer>

}


//export default useReduxState;