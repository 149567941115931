/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebRadioCategoryModel
 */
export interface FrontWebRadioCategoryModel {
    /**
     * Název
     * @type {string}
     * @memberof FrontWebRadioCategoryModel
     */
    name: string;
    /**
     * Slug
     * @type {string}
     * @memberof FrontWebRadioCategoryModel
     */
    slug: string;
    /**
     * Obrázek
     * @type {string}
     * @memberof FrontWebRadioCategoryModel
     */
    image: string;
}

/**
 * Check if a given object implements the FrontWebRadioCategoryModel interface.
 */
export function instanceOfFrontWebRadioCategoryModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "image" in value;

    return isInstance;
}

export function FrontWebRadioCategoryModelFromJSON(json: any): FrontWebRadioCategoryModel {
    return FrontWebRadioCategoryModelFromJSONTyped(json, false);
}

export function FrontWebRadioCategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebRadioCategoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'slug': json['slug'],
        'image': json['image'],
    };
}

export function FrontWebRadioCategoryModelToJSON(value?: FrontWebRadioCategoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'slug': value.slug,
        'image': value.image,
    };
}

