import {Aborted, useFetcher} from "./useFetcher";
import {ApiClient} from "../generated/ApiClient";
import type {FrontWebUserLikedModel} from "../generated";
import type { QueryClient } from '@tanstack/react-query'

let Memory: {
    [path: number]: { res: (d: Array<FrontWebUserLikedModel>) => void, rej: (e:any)=>void }[]
} = {

}

let resolving:any = false

const getData = (episodeId: number) => {
    return new Promise<Array<FrontWebUserLikedModel>>((res, rej) => {
        if (!Memory[episodeId])
            Memory[episodeId] = [];

        Memory[episodeId].push({
            res,
            rej
        });

        clearTimeout(resolving);

        const resolver = ()=> {
            const savedMemory = Memory;
            Memory = {};
            const keys = Object.keys(savedMemory);

            new ApiClient().getApiUserIsLikedEpisodes({ episodes: keys }, {
                credentials: "include",
                signal: Aborted().signal
            }).catch(e => {
                return [];
            }).then(response => {
                keys.forEach(x => {
                    const hasData = savedMemory[x as any as number];
                    if (hasData) {
                        let finded = response.filter(y => `${y.id}` === x);
                        hasData.forEach((making)=> {
                            making.res(finded);
                        });
                    }
                })
            })
        }
        if (Object.keys(Memory).length > 20)
            resolver();
        else
            resolving = setTimeout(resolver, 500);
    })
}

export const usePodcastEpisodeListenedTime = (episodeId:number) => ({
    queryKey: [`usePodcastEpisodeListenedTime-${episodeId}`],
    refetchOnMount: false,
    queryFn: useFetcher(() => getData(episodeId))
});


export const updatePodcastEpisodeListenedTime = async (episodeId:number ,  duration:number, queryClient: QueryClient, slug?: string) => {
    let queryData = queryClient.getQueryData([`usePodcastEpisodeListenedTime-${episodeId}`]) as Array<FrontWebUserLikedModel>|undefined;
    if (queryData?.length)
    {
        queryData = [{
            ...queryData[0],
            listenedTime: duration
        }]
    } else {
        queryData = [
            {
                listenedTime: duration,
                id: `${episodeId}`,
                slug: slug ? slug: "undefined"
            }
        ];
    }
    queryClient.setQueryData([`usePodcastEpisodeListenedTime-${episodeId}`], queryData);
}