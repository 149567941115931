/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebPodcastEpisodeModel } from './FrontWebPodcastEpisodeModel';
import {
    FrontWebPodcastEpisodeModelFromJSON,
    FrontWebPodcastEpisodeModelFromJSONTyped,
    FrontWebPodcastEpisodeModelToJSON,
} from './FrontWebPodcastEpisodeModel';

/**
 * 
 * @export
 * @interface FrontWebSearchResultEpisodesModel
 */
export interface FrontWebSearchResultEpisodesModel {
    /**
     * Počet stránek
     * @type {number}
     * @memberof FrontWebSearchResultEpisodesModel
     */
    pages: number;
    /**
     * Celkový počet
     * @type {number}
     * @memberof FrontWebSearchResultEpisodesModel
     */
    count: number;
    /**
     * Epizody
     * @type {Array<FrontWebPodcastEpisodeModel>}
     * @memberof FrontWebSearchResultEpisodesModel
     */
    result: Array<FrontWebPodcastEpisodeModel>;
}

/**
 * Check if a given object implements the FrontWebSearchResultEpisodesModel interface.
 */
export function instanceOfFrontWebSearchResultEpisodesModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function FrontWebSearchResultEpisodesModelFromJSON(json: any): FrontWebSearchResultEpisodesModel {
    return FrontWebSearchResultEpisodesModelFromJSONTyped(json, false);
}

export function FrontWebSearchResultEpisodesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebSearchResultEpisodesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pages': json['pages'],
        'count': json['count'],
        'result': ((json['result'] as Array<any>).map(FrontWebPodcastEpisodeModelFromJSON)),
    };
}

export function FrontWebSearchResultEpisodesModelToJSON(value?: FrontWebSearchResultEpisodesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pages': value.pages,
        'count': value.count,
        'result': ((value.result as Array<any>).map(FrontWebPodcastEpisodeModelToJSON)),
    };
}

