/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontApplicationInputStationToggleModel
 */
export interface FrontApplicationInputStationToggleModel {
    /**
     * Id rádia
     * @type {string}
     * @memberof FrontApplicationInputStationToggleModel
     */
    radioIdentity: string;
}

/**
 * Check if a given object implements the FrontApplicationInputStationToggleModel interface.
 */
export function instanceOfFrontApplicationInputStationToggleModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "radioIdentity" in value;

    return isInstance;
}

export function FrontApplicationInputStationToggleModelFromJSON(json: any): FrontApplicationInputStationToggleModel {
    return FrontApplicationInputStationToggleModelFromJSONTyped(json, false);
}

export function FrontApplicationInputStationToggleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationInputStationToggleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'radioIdentity': json['radio_identity'],
    };
}

export function FrontApplicationInputStationToggleModelToJSON(value?: FrontApplicationInputStationToggleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'radio_identity': value.radioIdentity,
    };
}

