/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebRadioModel } from './FrontWebRadioModel';
import {
    FrontWebRadioModelFromJSON,
    FrontWebRadioModelFromJSONTyped,
    FrontWebRadioModelToJSON,
} from './FrontWebRadioModel';

/**
 * 
 * @export
 * @interface FrontWebSimilarRadiosModel
 */
export interface FrontWebSimilarRadiosModel {
    /**
     * Name group
     * @type {string}
     * @memberof FrontWebSimilarRadiosModel
     */
    name: string;
    /**
     * Rádia
     * @type {Array<FrontWebRadioModel>}
     * @memberof FrontWebSimilarRadiosModel
     */
    radios: Array<FrontWebRadioModel>;
}

/**
 * Check if a given object implements the FrontWebSimilarRadiosModel interface.
 */
export function instanceOfFrontWebSimilarRadiosModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "radios" in value;

    return isInstance;
}

export function FrontWebSimilarRadiosModelFromJSON(json: any): FrontWebSimilarRadiosModel {
    return FrontWebSimilarRadiosModelFromJSONTyped(json, false);
}

export function FrontWebSimilarRadiosModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebSimilarRadiosModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'radios': ((json['radios'] as Array<any>).map(FrontWebRadioModelFromJSON)),
    };
}

export function FrontWebSimilarRadiosModelToJSON(value?: FrontWebSimilarRadiosModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'radios': ((value.radios as Array<any>).map(FrontWebRadioModelToJSON)),
    };
}

