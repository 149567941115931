import {FrontWebRadioModel} from "../../../generated";
import {RadioBox} from "../../Item/Radio/RadioBox";
import {Slider} from "../Slider";

export const RadioSlider = (props: {
    title: React.ReactNode,
    items: FrontWebRadioModel[]
}) => {
    if (props.items.length === 0)
        return null;

    return <>
        <h2 className={'h2 mb-24px text-center'}>{props.title}</h2>
        <Slider arrowTop={90}>
            {props.items.map(x => <RadioBox className={"pmt-12px pmb-12px pms-12px pme-12px"} key={x.id} radio={x}/>)}
        </Slider>
    </>
}