import { createSlice } from "@reduxjs/toolkit";

const rowlistSlice = createSlice( {
    name: "listrow",
    initialState: {
        list: true,
        popupsearch: false,
        search: false
    } as ListRowModel,
    reducers: {
        changeList: (state) => {
            return {
                ...state,
                list: !state.list
            };
        }
    }
})

export const { changeList } = rowlistSlice.actions;

export default rowlistSlice.reducer;