import Css from "./Footer.module.scss";
import {useState} from "react";
import { RouteLink} from "../RouteLink";

import radiaczimg from '../../static/radiacz.svg';
import newwindowwhiteimg from '../../static/newwindowwhite.svg';
import facebookwhiteimg from '../../static/facebookwhite.svg';
import instagramwhiteimg from '../../static/instagramwhite.svg';


export const Footer = () => {

    const [data] = useState(() => [
        {url: "https://statistiky.radia.cz", text: "Statistiky", m: 1, new: true},
        {url: " https://www.radiohouse.cz", text: "Reklama v rádiu", m: 1, new: true},
        {url: "/casto-kladene-otazky", text: "Často kladené dotazy", m: 1},
        {url: "/kontakt", text: "Kontakt", m: 1},
        {url: "/radia", text: "Rádia", m: 2},
        {url: "/podcasty", text: "Podcasty", m: 2},
        {url: "/stahnout-aplikaci", text: "Aplikace", m: 2},
        {url: "/jak-nas-naladite", text: "Jak si nás naladíte", m: 2},
    ]);

    return <footer className={`${Css.Footer}  pmt-36px pmb-24px`}>
        <div className={`d-flex flex-column flex-mmd-row justify-content-center align-items-center`}>
            <a href={"/"} className={`d-none ${Css.W25} text-center d-mmd-block`}>
                <img width={131} height={28} src={radiaczimg} alt={"radia.cz"}/>
            </a>
            <div className={`d-flex ${Css.W50} flex-column flex-mmd-row justify-content-around text-center`}>
                <ul className={"text-center text-mmd-start"}>
                    {data.filter(x => x.m === 1).map((v, k) => <li key={k}><RouteLink target={v.new ? "_blank" : "_self"}
                                                                                to={v.url}>{v.text} {v.new ?
                        <img className={Css.newwindow} src={newwindowwhiteimg} alt={"nové okno"}/> : false}</RouteLink>
                    </li>)}
                </ul>
                <ul className={"text-center  text-mmd-start"}>
                    {data.filter(x => x.m === 2).map((v, k) => <li key={k}><RouteLink target={v.new ? "_blank" : "_self"}
                                                                                to={v.url}>{v.text} {v.new ?
                        <img className={Css.newwindow} src={newwindowwhiteimg} alt={"nové okno"}/> : false}</RouteLink>
                    </li>)}
                </ul>
            </div>
            <div className={`${Css.W25} mt-24px mb-24px`}>
                <a  rel="noreferrer"  target="_blank" href={"https://internetova.radia.cz"} className={`btn btn-radiacz d-block w-75 text-center ${Css.Button}`}>
                    Chcete vlastní rádio?
                </a>
            </div>
        </div>
        <hr className={"ms-12px me-12px mt-24px mb-24px"}/>
        <div className={"pms-24px pme-24px"}>
        <div className={"d-flex flex-column flex-mmd-row justify-content-center justify-content-mmd-between "}>
            <ul className={"d-flex order-2 order-mmd-1  flex-column flex-mmd-row text-center text-mmd-start align-self-center"}>
                <li className={"ms-12px me-12px text-secondary"}>© 2023 radia.cz</li>
                <li className={"ms-12px me-12px"}><RouteLink to={"/vseobecne-podminky"}>Všeobecné podmínky</RouteLink></li>
                <li className={"ms-12px me-12px"}><RouteLink to={"/gdpr"}>Ochrana osobních údajů</RouteLink></li>
                <li className={"ms-12px me-12px"}><RouteLink onClick={e=>{
                    e.preventDefault();
                    // @ts-ignore
                    Didomi.preferences.show()
                }} to={"#preference"}>Nastavení soukromí</RouteLink></li>
            </ul>
            <ul className={"d-flex order-1 order-mmd-2 justify-content-center"}>
                <li className={"ms-12px me-12px"}><RouteLink target={"_blank"} title={"Facebook radia.cz"}
                                                        to={"https://www.facebook.com/radia.cz"}><img
                    src={facebookwhiteimg} alt={"facebook"}/></RouteLink></li>
                <li className={"ms-12px me-12px"}><RouteLink target={"_blank"} title={"Instagram radia.cz"}
                                                        to={"https://www.instagram.com/radia.cz/"}><img
                    src={instagramwhiteimg} alt={"instagram"}/></RouteLink></li>
            </ul>
        </div>
        </div>
    </footer>

}
