/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebStatisticsPositionModel } from './FrontWebStatisticsPositionModel';
import {
    FrontWebStatisticsPositionModelFromJSON,
    FrontWebStatisticsPositionModelFromJSONTyped,
    FrontWebStatisticsPositionModelToJSON,
} from './FrontWebStatisticsPositionModel';

/**
 * 
 * @export
 * @interface FrontWebStatisticsPositionsModel
 */
export interface FrontWebStatisticsPositionsModel {
    /**
     * Positions
     * @type {Array<FrontWebStatisticsPositionModel>}
     * @memberof FrontWebStatisticsPositionsModel
     */
    positions: Array<FrontWebStatisticsPositionModel>;
}

/**
 * Check if a given object implements the FrontWebStatisticsPositionsModel interface.
 */
export function instanceOfFrontWebStatisticsPositionsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function FrontWebStatisticsPositionsModelFromJSON(json: any): FrontWebStatisticsPositionsModel {
    return FrontWebStatisticsPositionsModelFromJSONTyped(json, false);
}

export function FrontWebStatisticsPositionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebStatisticsPositionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positions': ((json['positions'] as Array<any>).map(FrontWebStatisticsPositionModelFromJSON)),
    };
}

export function FrontWebStatisticsPositionsModelToJSON(value?: FrontWebStatisticsPositionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'positions': ((value.positions as Array<any>).map(FrontWebStatisticsPositionModelToJSON)),
    };
}

