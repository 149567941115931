/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebRefreshModel
 */
export interface FrontWebRefreshModel {
    /**
     * username
     * @type {string}
     * @memberof FrontWebRefreshModel
     */
    username: string;
}

/**
 * Check if a given object implements the FrontWebRefreshModel interface.
 */
export function instanceOfFrontWebRefreshModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function FrontWebRefreshModelFromJSON(json: any): FrontWebRefreshModel {
    return FrontWebRefreshModelFromJSONTyped(json, false);
}

export function FrontWebRefreshModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebRefreshModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
    };
}

export function FrontWebRefreshModelToJSON(value?: FrontWebRefreshModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
    };
}

