import {ApiClient} from "../generated/ApiClient";
import {Aborted, useFetcher} from "./useFetcher";
import {FrontWebRadioModel} from "../generated";

export const useRadioRecommendedKey = (silent = false) => ({
    queryKey: ["useRadioRecommendedKey"],
    queryFn: useFetcher(() => new ApiClient().getApiRadioRecommended({}, {
        credentials: "include",
        signal: Aborted().signal
    }).catch(e => {
        if (silent)
            return [] as Array<FrontWebRadioModel>
        throw e;
    })),
})