/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebProblemInputModel
 */
export interface FrontWebProblemInputModel {
    /**
     * mail
     * @type {string}
     * @memberof FrontWebProblemInputModel
     */
    mail: string;
    /**
     * problem description
     * @type {string}
     * @memberof FrontWebProblemInputModel
     */
    problem?: string;
    /**
     * token
     * @type {string}
     * @memberof FrontWebProblemInputModel
     */
    token: string;
    /**
     * url
     * @type {string}
     * @memberof FrontWebProblemInputModel
     */
    url: string;
}

/**
 * Check if a given object implements the FrontWebProblemInputModel interface.
 */
export function instanceOfFrontWebProblemInputModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mail" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function FrontWebProblemInputModelFromJSON(json: any): FrontWebProblemInputModel {
    return FrontWebProblemInputModelFromJSONTyped(json, false);
}

export function FrontWebProblemInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebProblemInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mail': json['mail'],
        'problem': !exists(json, 'problem') ? undefined : json['problem'],
        'token': json['token'],
        'url': json['url'],
    };
}

export function FrontWebProblemInputModelToJSON(value?: FrontWebProblemInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mail': value.mail,
        'problem': value.problem,
        'token': value.token,
        'url': value.url,
    };
}

