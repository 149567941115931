import React, {ReactNode, startTransition, useEffect, useRef, useState} from "react";
import 'react-slideshow-image/dist/styles.css'
import SliderModule from "./Slider.module.scss";
import {Slide, SlideshowRef} from "react-slideshow-image";
import debounce from "lodash.debounce";

import arrowleftsvg from '../../static/arrowleft.svg';
import arrowrightsvg from '../../static/arrowright.svg';

export type SliderProps = {
    children: ReactNode,
    arrowTop?: number
}

export const Slider = (props: SliderProps) => {

    const divRef = useRef<HTMLDivElement>(null);
    const slideRef = useRef<SlideshowRef>(null);
    const [selector, setSelector] = useState("");
    const [scrollElement, setScrollElement] = useState(0);
    const arrChildren = React.Children.count(props.children);
    const [base, setBaseSize] = useState(0);
    const [active, setActive] = useState(0);

    useEffect(() => {
        if (!selector)
            return;
        const resize = debounce(() => {
            startTransition(()=> {
                var current = divRef.current;
                if (!current)
                    return;
                var parentElement = divRef.current;
                let width = 0;
                if (parentElement) {
                    width = parentElement.getBoundingClientRect().width;
                }
                let baseSize = base;
                if (!baseSize) {

                    baseSize = Array.from(current.getElementsByClassName(selector)).map(item => {
                        return item.getBoundingClientRect()
                    }).reduce((max, item) => {
                        return item.width > max ? item.width : max
                    }, 0);
                    setBaseSize(baseSize);
                }
                const p = Math.floor(width / baseSize);
                setScrollElement(p === 0 ? 1 : p);
            });
        }, 300);
        resize();
        window.addEventListener("resize", resize);
        return () => window.removeEventListener("resize", resize);

    }, [selector, base]);

    useEffect(() => {
        startTransition(()=> {
            const selector = `selector-${new Date().getTime()}`;
            setSelector(selector);
        });
    }, [arrChildren]);
    const arrowTop = (props.arrowTop ? {
        top: props.arrowTop + 'px'
    } : {});

    return <div ref={divRef} className={"slider-wrapper"}>
        {scrollElement && scrollElement < React.Children.count(props.children) ?
            <div className={`d-flex ${SliderModule.slider} carousel slide`}>
                <a href="#back" onClick={e => {
                    e.preventDefault();
                    slideRef.current?.goBack();
                }}><img alt={"Přejít vlevo"} className={SliderModule.center} style={arrowTop} src={arrowleftsvg}/></a>
                <div>
                    <Slide transitionDuration={500} key={scrollElement}  onChange={(from, to)=>{
                        startTransition(()=> {
                            setActive(to);
                        });
                    }} arrows={false} slidesToShow={scrollElement} slidesToScroll={scrollElement} ref={slideRef}>
                        {React.Children.map(props.children, ((item, key) => (
                            <div key={key} className={`${selector} slider-width`}>
                                {item}
                            </div>)))}
                    </Slide>
                    <div className="carousel-indicators">
                        {(()=>{
                            const count = Math.ceil(React.Children.count(props.children) / scrollElement);
                            const sum = []
                            for (let i = 0; i < count; i++) {
                                sum.push(<button onClick={e=>{
                                    slideRef.current?.goTo(i * scrollElement)
                                }} key={i} type="button" className={`${Math.ceil(i ) === Math.ceil(active / scrollElement) ? "active" : ""} ms-2 me-2`}></button>);
                            }
                            return sum;
                        })()}
                    </div>
                </div>
                <a href={'#next'} onClick={e => {
                    e.preventDefault();
                    slideRef.current?.goNext();
                }}><img alt={"Přejít vpravo"} className={SliderModule.center} style={arrowTop} src={arrowrightsvg}/></a>
            </div> : <div className={"d-flex justify-content-center flex-wrap"}>{React.Children.map(props.children, (item, key) => (
                <div key={key} className={`${selector} slider-width`}>
                    {item}
                </div>))}
            </div>
        }
    </div>
}
