import {createContext, ReactNode, startTransition, useContext, useEffect, useMemo, useState} from "react";
import {useIsLoggedUser} from "../utils/UseIsLoggedUser";
import {usePodcastEpisodeListenedTime} from "../queriesKeys/usePodcastEpisodeListenedTime";
import {useQuery} from "@tanstack/react-query";


const Ctx = createContext({
    addDbTime: (id: number, set: (c: number)=>void): (() => void) => {
        return () => {

        }
    }
});


export const usePodcastTime = (id: number) => {
    const { addDbTime } = useContext(Ctx);
    const [time, setTime] = useState(0);
    const isLogged = useIsLoggedUser();
    const [inClient, setInClient] = useState(false);
    useEffect(() => {
        setInClient(true);
    }, []);

    const { data, isLoading } =  useQuery({...usePodcastEpisodeListenedTime(id), enabled: !!isLogged && inClient });

    useEffect(() => {
        if (isLoading || !inClient)
            return;
        if (data && data.length)
            setTime(data[0].listenedTime);

    }, [data, isLoading, inClient]);

    useEffect(() => addDbTime(id, setTime), [addDbTime, id]);
    return time;
}


export const PodcastTimes =  (props: {
    children: ReactNode
}) => {
    const isLogged = useIsLoggedUser();

    const [isLoggedUser, setIsLoggedUser] = useState(false);
    useEffect(() => {
        startTransition(() => setIsLoggedUser(!!isLogged));
    }, [isLogged]);


    const data = useMemo(() =>{
        return {} as { [path: string]: { time: number, setters: Array<((c:number) => void)> } };
        // eslint-disable-next-line
    }, [isLoggedUser]);

    const ctxValue = useMemo(() => {
        if (isLoggedUser) {
            return Object.freeze({
                addDbTime: (id:number, set: (c: number)=>void) => {

                    const specialId = `${id}`
                    if (!data[specialId]) {
                        data[specialId] = {time: -1, setters: [set]}
                    } else {
                        data[specialId].setters.push(set);
                        if (data[specialId].time > -1) {
                            const time = data[specialId].time;
                            (async () => {
                                startTransition(() => set(time));
                            })();
                        }
                    }
                    return () => {
                        const current = data[specialId];
                        if (current) {
                            current.setters = current.setters.filter(x => x !== set);
                        }
                    }
                },
                reset: (id: number, c: number) => {
                    const current = data[`${id}`];
                    if (current) {
                        current.time = c;
                        current.setters.forEach(x => x(c));
                    }
                }
            });
        } else {
            return Object.freeze({
                addDbTime: (id:number, set: (c: number)=>void) => {
                    return () => {}
                },
                reset: (id: number, c:number) => {

                }
            })
        }
        // eslint-disable-next-line
    }, [isLoggedUser]);


    return <Ctx.Provider value={ctxValue}>
        {props.children}
    </Ctx.Provider>
}


