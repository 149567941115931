import {useCallback, useEffect, useRef} from "react";

export const useAbortController = () => {

    const lastController = useRef<AbortController | null>(null);
    useEffect(() => {
        return () => {
            lastController.current?.abort();
        }
    }, []);


    return useCallback(() => {
        const onAbort = () => {
            lastController.current = null;
        }
        lastController.current?.abort();
        lastController.current = new AbortController();
        lastController.current.signal.addEventListener("abort", onAbort);
        return lastController.current;
    }, []);
}

