import type { Action } from "@reduxjs/toolkit";
export type GDPR = {tcString: string; gdprApplies: number} | null ;
export type GDPRAction = Action<"GDPR"> & {
    payload: GDPR
}

export const GdprReducer = (state: GDPR | undefined, action: GDPRAction) => {
    if (action.type === 'GDPR') {
        return action.payload;
    }
    return state ?? null;
}