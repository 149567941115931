import {useResetLoadbar} from "../contexts/useLoader";
import {ResponseError} from "../generated";
import {NotFoundErrorPage} from "./(error)/NotFoundErrorPage";
import {UndefinedErrorPage} from "./(error)/UndefinedErrorPage";
import {useEffect} from "react";
import {Response404Error} from "../generated/ApiClient";


export const FallbackError = (props: {
    error: any
}) => {

    const reset = useResetLoadbar();

    useEffect(() => {
        reset();
    }, [reset]);

    const error = props.error;
    if (error instanceof  ResponseError)
    {

        if (error.response.status === 404 || error instanceof Response404Error) {
            return <NotFoundErrorPage />;
        }
    }
    return <UndefinedErrorPage />

}