import {Advertisement} from "./Advertisement";
import {useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";

export const  AdformLeaderboard  = () => {
    const ref = useRef<HTMLDivElement|null>(null);
    const { pathname, search } = useLocation()

    useEffect(() => {
        let interval:any =null;
        interval = setInterval(()=> {
            if (Array.from(ref.current?.children ?? []).some(x=> {
                const style = (x as HTMLDivElement).style;
                return parseFloat(style.height) > 40;
            })) {
                clearInterval(interval);
                document.body.classList.add("advert_leaderboard")
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [pathname, search]);

    return <Advertisement  ref={ref} id={"adform_leaderboard"} style={{
        position: "absolute",
        top: 0
    }}/>
}
