import { RouteLink } from "../RouteLink";
import HeaderCss from './Header.module.scss';
import React, {ReactNode} from "react";
import {useLocation} from "react-router-dom";

export  const HeaderLink = (props: {
    href: string,
    className?: string,
    children: ReactNode,
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
}) => {
    const url = useLocation().pathname;
    return <RouteLink  onClick={props.onClick} to={props.href} className={`${props.className ?? ""}  ${url && url.indexOf(props.href) ? HeaderCss.headLink : ""}`}>{props.children}</RouteLink>;
}