/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontApplicationInputInputUserLoginModel
 */
export interface FrontApplicationInputInputUserLoginModel {
    /**
     * Mail
     * @type {string}
     * @memberof FrontApplicationInputInputUserLoginModel
     */
    email?: string;
    /**
     * Heslo
     * @type {string}
     * @memberof FrontApplicationInputInputUserLoginModel
     */
    password?: string;
    /**
     * Refresh token
     * @type {string}
     * @memberof FrontApplicationInputInputUserLoginModel
     */
    refreshToken?: string;
    /**
     * Validační kód pro registovaného uživatele
     * @type {string}
     * @memberof FrontApplicationInputInputUserLoginModel
     */
    code?: string;
    /**
     * Client
     * @type {string}
     * @memberof FrontApplicationInputInputUserLoginModel
     */
    clientId: string;
    /**
     * Secret
     * @type {string}
     * @memberof FrontApplicationInputInputUserLoginModel
     */
    clientSecret: string;
}

/**
 * Check if a given object implements the FrontApplicationInputInputUserLoginModel interface.
 */
export function instanceOfFrontApplicationInputInputUserLoginModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "clientSecret" in value;

    return isInstance;
}

export function FrontApplicationInputInputUserLoginModelFromJSON(json: any): FrontApplicationInputInputUserLoginModel {
    return FrontApplicationInputInputUserLoginModelFromJSONTyped(json, false);
}

export function FrontApplicationInputInputUserLoginModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationInputInputUserLoginModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'refreshToken': !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'clientId': json['client_id'],
        'clientSecret': json['client_secret'],
    };
}

export function FrontApplicationInputInputUserLoginModelToJSON(value?: FrontApplicationInputInputUserLoginModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'refresh_token': value.refreshToken,
        'code': value.code,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
    };
}

