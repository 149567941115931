/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebAdvertisementModel
 */
export interface FrontWebAdvertisementModel {
    /**
     * Id
     * @type {number}
     * @memberof FrontWebAdvertisementModel
     */
    id: number;
    /**
     * Doba mezi přehráním
     * @type {number}
     * @memberof FrontWebAdvertisementModel
     */
    hour: number;
    /**
     * Typ reklamy
     * @type {string}
     * @memberof FrontWebAdvertisementModel
     */
    type: string;
    /**
     * Odkaz na vast
     * @type {string}
     * @memberof FrontWebAdvertisementModel
     */
    url: string;
    /**
     * Odkaz na vast
     * @type {boolean}
     * @memberof FrontWebAdvertisementModel
     */
    onMobile: boolean;
}

/**
 * Check if a given object implements the FrontWebAdvertisementModel interface.
 */
export function instanceOfFrontWebAdvertisementModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "hour" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "onMobile" in value;

    return isInstance;
}

export function FrontWebAdvertisementModelFromJSON(json: any): FrontWebAdvertisementModel {
    return FrontWebAdvertisementModelFromJSONTyped(json, false);
}

export function FrontWebAdvertisementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebAdvertisementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hour': json['hour'],
        'type': json['type'],
        'url': json['url'],
        'onMobile': json['onMobile'],
    };
}

export function FrontWebAdvertisementModelToJSON(value?: FrontWebAdvertisementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hour': value.hour,
        'type': value.type,
        'url': value.url,
        'onMobile': value.onMobile,
    };
}

