/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontApplicationInputRenewPasswordModel
 */
export interface FrontApplicationInputRenewPasswordModel {
    /**
     * Staré heslo
     * @type {string}
     * @memberof FrontApplicationInputRenewPasswordModel
     */
    oldPassword: string;
    /**
     * Nové heslo
     * @type {string}
     * @memberof FrontApplicationInputRenewPasswordModel
     */
    newPassword: string;
}

/**
 * Check if a given object implements the FrontApplicationInputRenewPasswordModel interface.
 */
export function instanceOfFrontApplicationInputRenewPasswordModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "oldPassword" in value;
    isInstance = isInstance && "newPassword" in value;

    return isInstance;
}

export function FrontApplicationInputRenewPasswordModelFromJSON(json: any): FrontApplicationInputRenewPasswordModel {
    return FrontApplicationInputRenewPasswordModelFromJSONTyped(json, false);
}

export function FrontApplicationInputRenewPasswordModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationInputRenewPasswordModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldPassword': json['old_password'],
        'newPassword': json['new_password'],
    };
}

export function FrontApplicationInputRenewPasswordModelToJSON(value?: FrontApplicationInputRenewPasswordModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'old_password': value.oldPassword,
        'new_password': value.newPassword,
    };
}

