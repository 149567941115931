/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebContentModel
 */
export interface FrontWebContentModel {
    /**
     * Slug
     * @type {string}
     * @memberof FrontWebContentModel
     */
    slug: string;
    /**
     * Name
     * @type {string}
     * @memberof FrontWebContentModel
     */
    name: string;
    /**
     * Markdown
     * @type {string}
     * @memberof FrontWebContentModel
     */
    content: string;
}

/**
 * Check if a given object implements the FrontWebContentModel interface.
 */
export function instanceOfFrontWebContentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function FrontWebContentModelFromJSON(json: any): FrontWebContentModel {
    return FrontWebContentModelFromJSONTyped(json, false);
}

export function FrontWebContentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebContentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slug': json['slug'],
        'name': json['name'],
        'content': json['content'],
    };
}

export function FrontWebContentModelToJSON(value?: FrontWebContentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'name': value.name,
        'content': value.content,
    };
}

