/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebUserModel
 */
export interface FrontWebUserModel {
    /**
     * Id
     * @type {number}
     * @memberof FrontWebUserModel
     */
    id: number;
    /**
     * Mail uživatele
     * @type {string}
     * @memberof FrontWebUserModel
     */
    mail?: string;
    /**
     * Nový mail uživatele
     * @type {string}
     * @memberof FrontWebUserModel
     */
    newMail: string;
    /**
     * facebook
     * @type {boolean}
     * @memberof FrontWebUserModel
     */
    hasFacebook: boolean;
    /**
     * google
     * @type {boolean}
     * @memberof FrontWebUserModel
     */
    hasGoogle: boolean;
    /**
     * apple
     * @type {boolean}
     * @memberof FrontWebUserModel
     */
    hasApple: boolean;
}

/**
 * Check if a given object implements the FrontWebUserModel interface.
 */
export function instanceOfFrontWebUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "newMail" in value;
    isInstance = isInstance && "hasFacebook" in value;
    isInstance = isInstance && "hasGoogle" in value;
    isInstance = isInstance && "hasApple" in value;

    return isInstance;
}

export function FrontWebUserModelFromJSON(json: any): FrontWebUserModel {
    return FrontWebUserModelFromJSONTyped(json, false);
}

export function FrontWebUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mail': !exists(json, 'mail') ? undefined : json['mail'],
        'newMail': json['newMail'],
        'hasFacebook': json['hasFacebook'],
        'hasGoogle': json['hasGoogle'],
        'hasApple': json['hasApple'],
    };
}

export function FrontWebUserModelToJSON(value?: FrontWebUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mail': value.mail,
        'newMail': value.newMail,
        'hasFacebook': value.hasFacebook,
        'hasGoogle': value.hasGoogle,
        'hasApple': value.hasApple,
    };
}

