
import {useState} from "react";
import ImageCss from "./Image.module.scss";

import playradiosvg from '../../../static/playradio.svg'
export const LogoBox = (props: {
    src: string,
    alt: string,
    className?: string,
    onPlay?: () =>void,
    showPlay?: boolean
    shadow?: boolean
}) => {
    const [over, setOver] = useState(false);
    const svg = props.src.indexOf(".svg") > 0 ? ImageCss.svg : "";
    return <div className={`${ImageCss.LogoBox} ${ImageCss.Box} ${props.className ?? ""} ${props.shadow ? ImageCss.shadow : ""}`} onMouseOver={()=>{
        props.onPlay && setOver(true);
    }} onMouseLeave={() => {
        setOver(false);
    }}>
        <img src={props.src} alt={props?.alt ?? ""} className={`${over ? ImageCss.LogoOver : ""} ${svg} LogoImage`} />
        {over || props.showPlay ? <img alt={"přehrát rádio"} src={playradiosvg} className={ImageCss.PlayButton} onClick={e=>{
            e.preventDefault();
            props.onPlay?.();
        }}/>: false}
    </div>
}
