import {DefaultApi} from "./apis";
import {
    Configuration,
    ResponseError
} from "./runtime";

export class Response404Error extends ResponseError {

    public readonly ResponseCode = 404;


}

export class ApiClient extends DefaultApi {
    public constructor(bearer?: string, fetchApi?: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>) {
        super(new Configuration({
            basePath: process.env.REACT_APP_API_DOMAIN,
            accessToken: bearer,
        }));
    }

    public GetLastSongs(withNext: boolean = false) {
        return this.getApiSearchLastSongs({}, {
            credentials: "include"
        })
    }

    public GetLastPodcasts(recommended: boolean = false) {
        return this.getApiPodcastRecommendedPrograms({
        }, {
            credentials: "include"
        })
    }


}

export async function silent<T>(args: Promise<T>): Promise<T | undefined> {
    try {
        return await args;
    } catch (e) {
        if (e instanceof ResponseError) {
            if (e.response.status === 404) {
                return undefined
            }
        }
        throw e;
    }
}

export async function err403<T>(args: Promise<T>): Promise<T> {
    try {
        return await args;
    } catch (e) {

        if (e instanceof ResponseError) {
            if (e.response.status === 403 || e.response.status === 401) {
                throw e;
            }
        }
        throw e;
    }
}
