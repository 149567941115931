import {useLoader} from "../contexts/useLoader";
import {startTransition} from "react";

export function Aborted  () {
    const ab = new AbortController();
    setTimeout(() => {
        ab.abort();
    }, 20000)
    return ab;
}


export function useFetcher <T>(input: () => Promise<T>) {
    const loader = useLoader();

    return () => {
        return new Promise<T>((res, rej) =>{
            setTimeout(()=>{
                startTransition(()=> {
                    loader.addLoad();
                    input().then(res).catch(rej).finally(() => {
                        startTransition(()=> {
                            loader.removeLoad();
                        });
                    })
                })
            });
        });
    };
}