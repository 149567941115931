import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const currentUser = createSlice( {
    name: "currentUser",
    initialState: "",
    reducers: {
        hasCurrentUser: (state, value: PayloadAction<string>) => {
            return value.payload;
        }
    }
})

export const { hasCurrentUser } = currentUser.actions;

export default currentUser.reducer;