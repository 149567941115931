import { TuneOn} from "../../components/Footer/TuneOn";
import {lazy, startTransition, Suspense, useEffect, useState} from "react";
import {Loadbar} from "../../contexts/useLoader";
import { useSearchParams} from "react-router-dom";
const ErrorForm = lazy(() => import("../../components/Form/ErrorForm"))


export  const UndefinedErrorPage = ( props: {
    reportError?: boolean
} ) => {
    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        startTransition(() => setShowForm(true));
    }, []);

    const [params] = useSearchParams();

    return <main className={"main"}>
        <div className={"error-header mb-48px"}>
            <h1 className={"text-center  mt-36px mb-24px"}>
                {props.reportError ? "Nahlášení chyby" : "Vyskytla se nespecifikovaná chyba"}
            </h1>
            {!props.reportError &&
            <div className={"text-center fw-bold opacity-60  mb-24px"}>
                Se stránkou nebo souborem, který se snažíte otevřít, je praděpodobně něco v nepořádku.<br/>
                Omlouváme se za případné nepříjemnosti.
            </div>}
        </div>
        {showForm  ?
        <>
            <hr className={"mb-48px"}/>
            <div className={"pms-24px mb-48px"}>
                <p className={"text-center mb-36px"}>{!props.reportError ? <>Pokud si myslíte, že to, co hledáte, tady opravdu má být a chcete nám pomoci,<br/>
                    popište prosím do následujícího formuláře, jak jste se sem dostal(a) a co jste očekával(a) místo této chyby.</>: <>
                    Popište prosím, v čem spočívá chyba, kterou chcete nahlásit
                </>}</p>
                <Suspense fallback={<Loadbar loading={true} />}>
                    <ErrorForm email={null} url={params.get("url") || undefined}/>
                </Suspense>
            </div>
        </> : null}
        <hr className={"mb-48px"}/>
        <TuneOn/>
    </main>
}

export default UndefinedErrorPage;